import { debounce, throttle } from "throttle-debounce";

import { ApiCache } from "./apiCache";
import { addEventContext, initAnalytics, logEvent } from "./lib/analytics";
import ddcUtils from "./lib/ddc";
import domUtils from "./lib/dom";
import { IFrameModal } from "./lib/modal";
import { CacheItemType, CtaPlugin, DdcApi, DdcCtaPluginConfig, ExtractedItem, SuperlativeLookup, VehicleCondition } from "./lib/models";
import { getProductFullName } from "./lib/paymentsUtils";
import { debugMessage, getCookie, getPath, getPluginLocation, isMobile, shouldInstallWidgetOnDevice, sleep } from "./lib/pluginUtil";
import { TemplateCompiler } from "./lib/templateCompiler";
import { installTypeahead } from "./snap/typeahead";
import { hasLegacyChat, installSnapcellChat } from "./snapcell/live-chat";
import { installSpin, installSpinWithDDC } from "./snapcell/spin";
import { installSrpCtasWithDDC, installVideo, installVideoWithDDC } from "./snapcell/video";
import { trackSuperlativeView } from "./superlatives/tracker";

let DDC_API: DdcApi = null;
let linkPromise: Promise<void>;
let tp_linking_id: string;
let iframeModal: IFrameModal;

const TP_CTA_SELECTOR = "#tradepending-cta-container .tradepending-cta";
const AUTONATION_DEALERSHIP_NAME = "AutoNation USA";
const TOM_WOOD_DEALERSHIP_NAME = "Tom Wood Auto Group";
const AA_PRODUCT_MAP = {
  approve_banner: { product_name: "Approve", url: `${AUTOAPR_APP_URL}approve/` },
  payment_explorer_banner: { product_name: "Payment Explorer", url: `${AUTOAPR_APP_URL}explorer/` },
  payment_explorer_floating_button: { product_name: "Payment Explorer", url: `${AUTOAPR_APP_URL}explorer/` },
  reveal_landing_page: { product_name: "Reveal", url: `${AUTOAPR_APP_URL}reveal/`, use_condition_check: (vehicle) => true },
  approve_landing_page: { product_name: "Approve", url: `${AUTOAPR_APP_URL}approve/` },
  payment_explorer_landing_page: { product_name: "Payment Explorer", url: `${AUTOAPR_APP_URL}explorer/` },
  reveal_cta: { product_name: "Reveal", url: `${AUTOAPR_APP_URL}reveal/`, use_condition_check: (vehicle) => true, cta_title: "Calculate Your Payment", cta_intent: "payment-calculator" },
  lease_cta: { product_name: "Lease", url: `${AUTOAPR_APP_URL}reveal/lease/`, use_condition_check: (vehicle) => vehicle === VehicleCondition.NEW, cta_title: "Calculate Your Lease", cta_intent: "payment-calculator" },
  approve_cta: { product_name: "Approve", url: `${AUTOAPR_APP_URL}approve/`, use_condition_check: (vehicle) => true, cta_title: "Get Prequalified Now", cta_intent: "pre-approval" },
  test_drive_cta: { product_name: "Test Drive Plus", url: `${AUTOAPR_APP_URL}drive/`, use_condition_check: (vehicle) => true, cta_title: "Schedule a Test Drive", cta_intent: "test-drive" },
  combined_cta: { product_name: "Whatever", url: `${AUTOAPR_APP_URL}explorer/`, use_condition_check: (vehicle) => true, ctas: ["reveal_cta", "lease_cta", "approve_cta", "test_drive_cta"] },
  combined_banner: { product_name: "Payment Explorer/Approve", url: (product: string) => `${AUTOAPR_APP_URL}${product}/` },
};

const CTAS_MAP = {
  test_drive_v2_cta: { product_name: "Test Drive Plus", type: "test_drive_v2", use_condition_check: (vehicle) => true, cta_title: "Schedule a Test Drive", cta_intent: "test-drive" },
  availability_cta: { product_name: "Availability", type: "availability", use_condition_check: (vehicle) => true, cta_title: "Confirm Availability", cta_intent: "check-availability" },
  eprice_cta: { product_name: "ePrice", type: "eprice", use_condition_check: (vehicle) => true, cta_title: "Get ePrice", cta_intent: "eprice" },
};

const VALUE_TRACKER_MAP = {
  value_tracker_cta: { product_name: "Value Tracker", type: "value_tracker", cta_title: "Track Value", cta_intent: "track-value" },
  value_tracker_banner: { product_name: "Value Tracker", type: "value_tracker" },
  value_tracker_landing_page: { product_name: "Value Tracker", type: "value_tracker" },
};

async function bootstrap() {
  if (window.TradePendingPlugin === true) {
    console.log("TradePendingPlugin already exists so will not be loaded again.");
    return;
  }
  window.TradePendingPlugin = true;
  if (document.readyState === "complete" || document.readyState === "interactive") {
    setup_tradepending();
  } else {
    if (PLUGIN_CONFIG.website_vendor_name === "DealerInspire") {
      document.addEventListener("readystatechange", () => {
        if (document.readyState === "complete") {
          setup_tradepending();
        }
      });
    } else {
      window.addEventListener("load", setup_tradepending);
    }
  }
}

function setup_tradepending() {
  class TPUtil {
    originalOpen: typeof XMLHttpRequest.prototype.open;
    originalFetch: typeof window.fetch;
    doThrottleMutationObservers = throttle(200, this.doSetupMutationObservers, { noLeading: true });
    setupMutationObservers = debounce(100, this.doThrottleMutationObservers);

    tp_decorate(url: string) {
      url += "&session_aid=" + tp_analytics_session_id;
      let r = document.cookie.match(/tpa_user=(.*?)(?:;|$)/);
      if (r) {
        url += "&user_aid=" + r[1];
      }
      r = document.cookie.match(/tpa_actions=(.*?)(?:;|$)/);
      if (r) {
        url += "&actions=" + r[1];
      }
      r = document.cookie.match(/tpa_vdp=(.*?)(?:;|$)/);
      if (r) {
        url += "&survey_id=" + r[1];
      }
      if (tp_linking_id) {
        url += `&linking_id=${tp_linking_id}`;
      }
      if (tp_analytics_session_id_is_new) {
        url += "&newsession=true";
        tp_analytics_session_id_is_new = false;
      }
      return url;
    }

    referer_decorate(url: string) {
      let r = document.cookie.match(/tp_initial_url=(.*?)(?:;|$)/);
      if (r) {
        url += "&initial_url=" + encodeURIComponent(r[1]);
      }
      r = document.cookie.match(/tp_referrer_url=(.*?)(?:;|$)/);
      if (r) {
        url += "&referrer_url=" + encodeURIComponent(r[1]);
      }
      r = document.cookie.match(/tp_snap_lead_id=(.*?)(?:;|$)/);
      if (r) {
        url += "&snap_lead_id=" + encodeURIComponent(r[1]);
      }
      return url;
    }

    vehicle_decorate(url: string, plugin_cfg) {
      if (plugin_cfg.vehicle_condition && plugin_cfg.vehicle_condition.condition) {
        url += "&cd=" + (plugin_cfg.vehicle_condition.condition === VehicleCondition.NEW ? "n" : "u");
      }
      if (plugin_cfg.price) {
        url += "&price=" + plugin_cfg.price;
      }
      if (plugin_cfg.untie_stock_number_from_vin) {
        url += `&use_stock_number=${plugin_cfg.untie_stock_number_from_vin}`;
      }
      return url;
    }

    get_primary_ga_id() {
      if (GA_ID) {
        const split = GA_ID.split(",");
        return split[0].trim();
      } else {
        return null;
      }
    }

    ga_decorate(url: string) {
      try {
        const gao = get_ga_object();
        if (gao) {
          const primary_ga_id = this.get_primary_ga_id();
          if (primary_ga_id != null) {
            let tracker = getGaTracker(primary_ga_id);
            if (tracker == null && typeof gao?.getAll === "function") {
              tracker = gao.getAll()[0];
            }
            gao("require", "linker");
            if (window.gaplugins && window.gaplugins.Linker && tracker) {
              const linker = new window.gaplugins.Linker(tracker);
              url = linker.decorate(url, true);
              if (DEBUG_ENABLED) debugMessage("GA decorated URL: " + url);
            }
          }
        }
      } catch (ex) {
        console.log("Error in TradePending.ga_decorate", ex);
      }
      try {
        const r = document.cookie.match(/_gcl_aw=(.*?)(?:;|$)/);
        if (r) {
          url += "&gcl_aw_id=" + r[1];
        }
      } catch (ex) {
        console.log("Error in TradePending.ga_decorate extracting gcl_aw_id", ex);
      }
      return url;
    }
    shift_decorate(url: string) {
      let r = document.cookie.match(/tp_shift_session_id=(.*?)(?:;|$)/);
      if (r) {
        url += "&shift_session_id=" + r[1];
      }
      return url;
    }

    tealium_decorate(url: string) {
      if (window.utag?.data?.tealium_visitor_id) {
        url += "&tealium_visitor_id=" + window.utag.data.tealium_visitor_id;
      }
      return url;
    }

    isLandingOrInline() {
      const plugin_location = getPluginLocation();
      const plugin = PLUGIN_DATA[plugin_location];

      return plugin?.inline_mobile || plugin_location === "landing";
    }

    isIE() {
      const ua = window.navigator.userAgent;
      return ua.indexOf("MSIE ") > 0 || ua.indexOf("Trident/") > 0 || ua.indexOf("Edge/") > 0;
    }

    exists(str: string) {
      return typeof str !== "undefined" && str.length > 0;
    }

    getCssSelector(plugin: { css_selector?: string }) {
      return plugin.css_selector;
    }

    do_refresh(pluginLocationConfigs: LocationConfigs, triggerFunction: () => void) {
      if (SUPERLATIVES_ENABLED) setup_superlatives();
      if (triggerFunction) {
        triggerFunction();
        this.setup_refresh_events(pluginLocationConfigs, triggerFunction);
      }
    }

    setup_refresh_events(pluginLocationConfigs: LocationConfigs, triggerFunction: () => void) {
      let location_config = { ...(PLUGIN_LOCATION_CONFIGS[getPluginLocation()] || {}) };
      let observe_id: any = null;
      const self = this;
      const refreshFunction = debounce(1000, () => this.do_refresh(pluginLocationConfigs, triggerFunction));
      if (location_config?.has_been_setup) {
        return;
      } else {
        location_config.has_been_setup = true;
      }
      if (location_config.ajax_url) {
        if (!this.originalOpen) {
          this.originalOpen = XMLHttpRequest.prototype.open;
        }
        // @ts-ignore
        XMLHttpRequest.prototype.open = function (method: string, url: string | URL, async: boolean, username?: string | null, password?: string | null) {
          let shouldRefresh = false;
          location_config = pluginLocationConfigs[getPluginLocation()];
          if (url.toString().match(new RegExp(location_config.ajax_url)) && !url.toString().includes(PLUGIN_AJAX_URL)) {
            shouldRefresh = true;
          }
          this.addEventListener(
            "readystatechange",
            function () {
              if (this.readyState === 4 && shouldRefresh) {
                if (DEBUG_ENABLED) debugMessage("Ajax URL refresh triggered by URL: " + url.toString());
                if (location_config.force_reload_on_ajax) {
                  window.TradePendingReloadSuperlatives = true;
                }
                self.do_refresh(pluginLocationConfigs, triggerFunction);
              }
            },
            false
          );
          self.originalOpen.apply(this, arguments);
        };
        if (!this.originalFetch && window.fetch !== this.originalFetch) {
          if (DEBUG_ENABLED) debugMessage("Overriding fetch");
          this.originalFetch = window.fetch;

          window.fetch = function (input, init) {
            if (input?.toString().match(new RegExp(location_config.ajax_url)) && !input?.toString().includes(PLUGIN_AJAX_URL)) {
              if (location_config.force_reload_on_ajax) {
                window.TradePendingReloadSuperlatives = true;
              }

              if (location_config.observer_selector) {
                self.setupMutationObservers(location_config.observer_selector, 0, refreshFunction, location_config.observer_selector_reinit);
              }
              self.do_refresh(pluginLocationConfigs, triggerFunction);
            }
            return self.originalFetch.apply(this, arguments);
          };
        }
      }
      if (location_config.watch_hash_change) {
        window.addEventListener(
          "hashchange",
          () => {
            self.do_refresh(pluginLocationConfigs, triggerFunction);
          },
          false
        );
      }
      if (location_config.observer_selector != null && location_config.observer_selector.length > 0) {
        observe_id = location_config.observer_selector;
      } else if (PLUGIN_CONFIG.website_vendor_name === "CDK-NextGen" || PLUGIN_CONFIG.website_vendor_name === "Sincro") {
        observe_id = "#main, .deck";
      }

      if (observe_id != null) {
        this.setupMutationObservers(observe_id, 0, refreshFunction, location_config.observer_selector_reinit);
      } else if (PLUGIN_CONFIG.website_vendor_name === "DealerInspire") {
        if (DEBUG_ENABLED) debugMessage("Setup Superlatives listener for vrp-ready");
        if (typeof jQuery !== "undefined") {
          // jQuery needed for custom events
          jQuery(document).on("vrp-ready vrp-ajax-complete", () => {
            if (DEBUG_ENABLED) debugMessage("Superlatives got vrp-ready event");
            self.do_refresh(pluginLocationConfigs, triggerFunction);
          });
        }
      }
      if ("onorientationchange" in window) {
        window.addEventListener("orientationchange", () => {
          window.TradePendingReloadSuperlatives = true;
          self.do_refresh(pluginLocationConfigs, triggerFunction);
        });
      }
    }

    doSetupMutationObservers(observe_id: string, retryCount: number, refreshFunction: () => void, observer_selector_reinit?: string) {
      const self = this;

      if (DEBUG_ENABLED) debugMessage("Setup MutationObserver on: " + observe_id);

      const observedElement = document.getElementById(observe_id);
      let observedElements;
      if (!observedElement) {
        observedElements = document.querySelectorAll(observe_id);
      }
      const reinitializeElements = (observer_selector_reinit && document.querySelectorAll(observer_selector_reinit)) || [];
      if (DEBUG_ENABLED) debugMessage("Setup MutationObserver reinitializeElements" + reinitializeElements);

      if (observedElement || observedElements) {
        const mutationObserver = new MutationObserver((mutations) => {
          mutations.some((mutation) => {
            if (observedElement && mutation.target === observedElement) {
              if (DEBUG_ENABLED) debugMessage("Element hit in MutationObserver:" + observedElement);
              return refreshFunction();
            } else if (observedElements) {
              for (const el of observedElements) {
                if (el === mutation.target) {
                  if (DEBUG_ENABLED) debugMessage("Element hit in MutationObserver:" + el);
                  let setupAgain = false;
                  for (let j = 0; j < reinitializeElements.length && !setupAgain; j++) {
                    const reinitElement = reinitializeElements[j];
                    if (mutation.target === reinitElement) {
                      setupAgain = true;
                    }
                  }
                  if (setupAgain) {
                    if (DEBUG_ENABLED) debugMessage("Superlatives MutationObserver: setting up again mutation found" + observer_selector_reinit);

                    setTimeout(() => self.setupMutationObservers(observe_id, retryCount, refreshFunction, observer_selector_reinit), 100);
                  }
                  return refreshFunction();
                }
              }
            }
          });
        });

        if (observedElement) {
          mutationObserver.observe(observedElement, {
            attributes: false,
            characterData: false,
            childList: true,
            subtree: true,
            attributeOldValue: false,
            characterDataOldValue: false,
          });
        } else if (observedElements) {
          observedElements.forEach((el) => {
            mutationObserver.observe(el, {
              attributes: false,
              characterData: false,
              childList: true,
              subtree: true,
              attributeOldValue: false,
              characterDataOldValue: false,
            });
          });
        }
      } else {
        if (retryCount <= 3) {
          setTimeout(() => {
            self.doSetupMutationObservers(observe_id, retryCount + 1, refreshFunction, observer_selector_reinit);
          }, 1000 * retryCount);
        }
      }
    }

    parse_query_params(url: string) {
      try {
        const searchParams = new URLSearchParams(url);
        return Object.fromEntries(searchParams.entries());
      } catch (e) {
        console.log("TP exception parsing query params: " + e, e.message);
        return {};
      }
    }

    add_utm_params_to_iframe(widget_html: string, utm_only: boolean) {
      const iframe_regex = /<iframe.*\/>|<iframe.*><\/iframe>/;
      const iframe = widget_html.match(iframe_regex);
      const src = iframe[0].match(/src=".*"/)[0];
      const split_url = src.split("src=")[1];
      let url = split_url.slice(1, split_url.length - 1);
      if (url) {
        if (utm_only) {
          const params = tpUtil.parse_query_params(window.location.search);
          const new_utm_search = Object.keys(params).reduce((memo, paramKey) => {
            if (paramKey.startsWith("utm")) {
              memo = memo + `${paramKey}=${params[paramKey]}`;
            }
            return memo;
          }, "&");
          if (url.includes("?")) {
            url = `${url}${new_utm_search}`;
          } else {
            url = url + "?" + new_utm_search.slice(1);
          }
          url = this.referer_decorate(url);
        } else {
          url = this.tp_decorate(url);
          url = this.referer_decorate(url);
          url = this.ga_decorate(url);
          url = this.tealium_decorate(url);
        }
      }
      if (url?.endsWith("?")) {
        url = url.slice(0, src.length - 1);
      }
      const new_src = `src='${url}'`;

      const new_iframe = iframe[0].replace(/src=".*"/, new_src);
      return widget_html.replace(iframe_regex, new_iframe);
    }
  }

  const tpUtil = new TPUtil();
  const superlativesApiCache = SUPERLATIVES_ENABLED ? new ApiCache(CacheItemType.SUPERLATIVE) : null;
  const vehiclesConditionApiCache = new ApiCache(CacheItemType.VEHICLE_CONDITION);
  const vinsFromStockNumbersApiCache = new ApiCache(CacheItemType.STOCK_NUMBER_VIN);
  const autoAprVinConditionApiCache = new ApiCache(CacheItemType.AUTOAPR_VIN_CONDITION);

  // Start of SNAP Superlatives
  let superlatives_setup_called = false;
  let currentPluginLocation;
  let currentPluginIsMobile;

  function add_superlative_config_iframe() {
    const url = "//" + PLUGIN_SERVER + "/superlatives/config_iframe";
    const html = "<iframe id='tradepending-superlatives-iframe' src='" + url + "' width='0px' height='0px' frameborder='0' scrolling='no' style='display: none;'></iframe>";
    document.body.appendChild(domUtils.toElement(html));
  }

  function get_superlative_defaults() {
    return {
      poweredBy: undefined,
      icon: undefined,
      fonts: {
        rootSize: "10px",
      },
      border: {
        color: "#b5b4b6",
      },
      borderRadius: "8px",
      button: {
        borderRadius: "0px",
      },
      container: {
        maxWidth: "400px",
      },
      colors: {
        container: {
          background: "white",
          font: "black",
        },
        primary: {
          background: "#f74d03",
          font: "white",
        },
        secondary: {
          background: "#f76841",
          font: "white",
        },
        gradient: {
          start: undefined,
          end: "#EFEDED",
        },
      },
    };
  }

  function handle_superlative_templates(location_config: SuperlativeLocationConfig) {
    const variables = get_superlative_defaults();
    let roundedButton = true;
    if (location_config.variables) {
      roundedButton = location_config.rounded_buttons;
      Object.keys(location_config.variables).forEach(function (key) {
        const value = location_config.variables[key];
        const path = key.split("-");
        let variable = variables;
        path.forEach(function (pathVal) {
          const temp = variable[pathVal];
          if (typeof temp == "object") {
            variable = temp;
          } else if (temp) {
            variable[pathVal] = value;
          }
        });
      });
    }
    if (roundedButton) {
      variables.button = {
        borderRadius: roundedButton ? "10px" : "0px",
      };
    }

    variables.icon = {
      size: "40px",
    };
    variables.poweredBy = {
      height: "17px",
      width: "85px",
    };
    variables.colors.gradient.start = variables.colors.container.background;
    return variables;
  }

  function shouldShowSuperlatives(loc: string) {
    const cleanLoc = loc?.replace("_mobile", "") || "";
    const isVdpOrSRP = cleanLoc.startsWith("vdp") || cleanLoc.startsWith("srp") || cleanLoc.endsWith("srp") || cleanLoc.endsWith("vdp");
    return isVdpOrSRP && showSuperlativeBasedOnStatus() && shouldProcessSuperlatives();
  }

  const INACTIVE_STATUSES = ["canceled_disabled", "hold"];

  function isActiveStatus() {
    return !INACTIVE_STATUSES.includes(SUPERLATIVE_CONFIG.status);
  }

  function showSuperlativeBasedOnStatus() {
    if (isActiveStatus()) {
      if (SUPERLATIVE_CONFIG.status === "testing") {
        return document.cookie.match(/tp_test_product=true/) != null || window.location.hostname.match(/\.dev\.dealerinspire\.com/);
      } else if (SUPERLATIVE_CONFIG.status === "pending") {
        postAnalytics("notify");
        return false;
      } else if (isBlockedStagingSite(SUPERLATIVE_CONFIG.status)) {
        return false;
      } else {
        return true;
      }
    }
  }

  function shouldProcessSuperlatives() {
    const path = getPath();
    const regex = DEALER_SUPERLATIVE_CONFIG?.ignore_regex;
    if (!regex) {
      return true;
    }
    const match = !path.match(new RegExp(regex, "i"));
    if (!match) {
      if (DEBUG_ENABLED) debugMessage("Skipping superlatives because the regex is ignoring this path:", path, regex);
    }
    return match;
  }

  function insert_ddc_superlatives(location_config: SuperlativeLocationConfig, vin_list: string[]) {
    if (!HAS_DDC) return;
    let location_config_html = location_config.html_compiled || location_config.html;
    if (location_config.enable_build_data) {
      const htmlSnippet = domUtils.toElement(`<div>${location_config_html}</div>`);
      const buildDataHtml = htmlSnippet.querySelector<HTMLElement>(".tp-build-data-wrapper");
      buildDataHtml?.removeAttribute("hidden");
      location_config_html = htmlSnippet.innerHTML;
    }
    const templateCompiler = new TemplateCompiler(location_config_html);
    const selector = location_config.selector || "vehicle-media";
    if (DEBUG_ENABLED) debugMessage(`Insert superlative widgets at ${selector} for vins:`, vin_list);
    fetch_superlatives(vin_list, 0).then((result) => {
      const datamap: { [vin: string]: SuperlativeLookup } = {};
      result.data.forEach((record) => {
        datamap[record.vin] = record;
      });
      DDC_API.insertOnce(selector, (elem, meta) => {
        const superlative_info = datamap[meta.vin];
        if (!superlative_info) {
          return;
        }
        const missing = !elem.parentElement.querySelector(`.${getRootClass()} .tp-superlative-data[data-vin="${superlative_info.vin}"]`);
        if (!missing) {
          return;
        }
        if (DEBUG_ENABLED) debugMessage(`Insert superlative widget for vin: ${meta.vin} with superlative: ${superlative_info.superlative}`);
        const outputElem = build_superlatives_html(superlative_info, result.template_html, templateCompiler, location_config);
        const widget_elem = outputElem;
        DDC_API.append(elem, widget_elem);
        finalize_inserted_element(location_config, superlative_info, widget_elem, outputElem);
      });
      if (result.data?.length === 1 && "superlative" in result.data[0]) {
        postAnalytics("superlative_" + get_superlative_name_key(result.data[0].superlative));
      } else {
        postAnalytics("superlative_multiple");
      }
    });
  }

  function setup_superlatives_ddc() {
    if (!HAS_DDC) return;
    if (superlatives_installed) {
      if (DEBUG_ENABLED) debugMessage("TradePending skipping setting up Superlatives for DDC since its already been done");
      return;
    }
    superlatives_installed = true;
    if (DEBUG_ENABLED) debugMessage("Setting up superlatives DDC with config data:", SUPERLATIVE_CONFIG);
    const location_config = setup_superlatives_location_config(SUPERLATIVE_CONFIG);
    if (location_config.disabled) {
      superlatives_setup_called = true;
      return;
    }
    if (!superlatives_setup_called) {
      add_superlative_config_iframe();
      superlatives_setup_called = true;
    }
    handleLocationOrMobileChange(location_config);

    DDC_API.subscribe("vehicle-data-updated-v1", (data) => {
      if (DEBUG_ENABLED) debugMessage("DDC vehicle-data-updated-v1", data);
      if (!data.payload?.vehicleData) {
        if (DEBUG_ENABLED) debugMessage("DDC vehicle-data-updated-v1 returned no vehicle data");
      } else {
        const vin_list = data.payload.vehicleData.map((vdata) => vdata.vin);
        insert_ddc_superlatives(location_config, vin_list);
      }
    });
  }

  const do_throttled_setup_superlatives = SUPERLATIVES_ENABLED ? throttle(200, do_setup_superlatives) : null;
  const setup_superlatives = SUPERLATIVES_ENABLED ? debounce(100, do_throttled_setup_superlatives) : null;

  function setup_superlatives_location_config(SUPERLATIVE_CONFIG: SuperlativeConfig) {
    const pluginLocation = getPluginLocation();
    const pluginLocationConfig = PLUGIN_LOCATION_CONFIGS[pluginLocation];

    let superlativeLocation = pluginLocation;
    if (pluginLocationConfig?.location_type && pluginLocationConfig?.location_type != "auto") {
      superlativeLocation = pluginLocationConfig?.location_type;
    }

    const desktopConfig = SUPERLATIVE_CONFIG.locations[superlativeLocation];
    const mobileConfig = SUPERLATIVE_CONFIG.locations[superlativeLocation + "_mobile"];

    let locationConfig = { ...desktopConfig };
    // @ts-ignore
    if (isMobile() && mobileConfig !== null) {
      locationConfig = { ...locationConfig, ...mobileConfig };
    }
    if (!locationConfig.css) {
      locationConfig.css = "";
    }
    if (!locationConfig.template_css) {
      locationConfig.template_css = "";
    }
    const srp_config = SUPERLATIVE_CONFIG.locations["srp"];
    if (!locationConfig?.html_compiled) {
      locationConfig.html_compiled = srp_config?.html_compiled;
    }
    if (!locationConfig?.css_compiled) {
      locationConfig.css_compiled = srp_config?.css_compiled;
    }
    if (!locationConfig?.variables) {
      locationConfig.variables = srp_config?.variables;
    }
    return locationConfig;
  }

  function addOrReplaceSuperlativesCssTag(locationConfig: SuperlativeLocationConfig) {
    const id = "tradepending-superlatives-css";
    const superlativeCssElement = document.getElementById(id);
    const variables = handle_superlative_templates(locationConfig);
    const styles = SUPERLATIVE_CSS + locationConfig.template_css + locationConfig.css + locationConfig.css_compiled;
    const templateCompiler = new TemplateCompiler(styles);
    const superlativeCss = templateCompiler.compile(variables);
    if (superlativeCssElement) {
      superlativeCssElement.innerHTML = superlativeCss;
    } else {
      const style = document.createElement("style");
      style.id = id;
      style.innerHTML = superlativeCss;
      document.head.appendChild(style);
    }
  }

  function handleLocationOrMobileChange(locationConfig: SuperlativeLocationConfig) {
    const pluginLocation = getPluginLocation();
    // @ts-ignore
    const mobile = isMobile();
    if (pluginLocation === currentPluginLocation && mobile === currentPluginIsMobile) {
      return;
    }
    currentPluginLocation = pluginLocation;
    currentPluginIsMobile = mobile;
    addOrReplaceSuperlativesCssTag(locationConfig);
  }

  function do_setup_superlatives() {
    if (DEBUG_ENABLED) debugMessage("Setting up superlatives with config data:", SUPERLATIVE_CONFIG);
    const location_config = setup_superlatives_location_config(SUPERLATIVE_CONFIG);
    if (location_config.disabled) {
      superlatives_setup_called = true;
      return;
    }
    if (!superlatives_setup_called) {
      const pluginLocation = getPluginLocation();
      add_superlative_config_iframe();
      if (DEBUG_ENABLED) debugMessage("superlatives location_config: ", pluginLocation, location_config);
      superlatives_setup_called = true;
      PLUGIN_LOCATION_CONFIGS[getPluginLocation()].has_been_setup = false;
    }
    handleLocationOrMobileChange(location_config);

    if (DEBUG_ENABLED) debugMessage("Inserting TP Superlatives using insert_selector " + location_config.selector + " and insert method: " + location_config.insert_method);
    let location_config_html = location_config.html_compiled || location_config.html;
    if (location_config.enable_build_data) {
      const htmlSnippet = domUtils.toElement(`<div>${location_config_html}</div>`);
      const buildDataHtml = htmlSnippet.querySelector<HTMLElement>(".tp-build-data-wrapper");
      buildDataHtml?.removeAttribute("hidden");
      location_config_html = htmlSnippet.innerHTML;
    }

    const templateCompiler = new TemplateCompiler(location_config_html);
    const vin_selector_raw = location_config.vin_selector;
    const vin_list: string[] = [];
    const outputContainer: { [vin: string]: HTMLElement } = {};
    const container_node = domUtils.jqSelect<HTMLElement>(location_config.node_selector);
    let loaded;
    if (DEBUG_ENABLED) debugMessage("TP Superlatives: Looking for vins:", location_config.node_selector, vin_selector_raw);
    if (container_node.length > 0) {
      container_node.forEach((element, idx) => {
        reportHiddenNodeSelector(element, location_config.node_selector);
        loaded = element.dataset.tpSuperlativeLoaded;
        if (!loaded || window.TradePendingReloadSuperlatives) {
          const vin = getDataFromElement(element, vin_selector_raw, ExtractedItem.VIN);
          if (vin) {
            vin_list.push(vin);
          }
          outputContainer[vin] = element;
          element.dataset.tpSuperlativeLoaded = "true";
        }
      });
      window.TradePendingReloadSuperlatives = false;
    } else {
      console.error("TradePending: Superlatives didn't find any nodes, check the node_selector:", location_config.node_selector);
      return;
    }
    if (DEBUG_ENABLED) debugMessage("Superlatives found vins:", vin_list);

    if (vin_list.length == 0) {
      if (loaded) {
        if (DEBUG_ENABLED) debugMessage("TP Superlatives already loaded, we're not going to refresh.");
        return;
      }
      console.error("TradePending: Superlatives found no vins on this page.  Check the vin_selector:", vin_selector_raw);
    } else {
      fetch_superlatives(vin_list, 0).then((result) => {
        let insert_failed = false;
        result.data.forEach(function (superlative_info, idx) {
          if (draw_superlative_box(idx, superlative_info, outputContainer, templateCompiler, location_config, result.template_html)) {
            insert_failed = true;
          }
        });
        if (insert_failed) {
          console.error("TradePending: Superlatives Error: check the insert selector", location_config.selector);
        } else if (result.data.length === 1 && "superlative" in result.data[0]) {
          postAnalytics("superlative_" + get_superlative_name_key(result.data[0].superlative));
        } else {
          postAnalytics("superlative_multiple");
        }
      });
    }
  }

  async function fetch_superlatives(vin_list: string[], retries: number) {
    if (!vin_list || vin_list.length === 0) {
      return;
    }
    if (typeof SUPERLATIVE_CONFIG.allow_config === "undefined" && retries < 5) {
      if (DEBUG_ENABLED) debugMessage("no config found, settimeout", SUPERLATIVE_CONFIG);
      setTimeout(() => {
        fetch_superlatives(vin_list, retries + 1);
      }, 200);
      return await do_fetch_superlatives(vin_list);
    } else {
      return await do_fetch_superlatives(vin_list);
    }
  }

  async function do_fetch_superlatives(vin_list: string[]) {
    const superlativePluginLocation = getPluginLocation();
    let url = "//" + PLUGIN_SERVER + "/superlatives/lookup?did=" + DEALER_ID + "&plugin_id=" + PLUGIN_ID + "&location=" + superlativePluginLocation;
    if (SUPERLATIVE_CONFIG.user_id) {
      url = url + "&user_id=" + SUPERLATIVE_CONFIG.user_id;
    } else if (SUPERLATIVE_CONFIG.demo) {
      url = url + "&demo=" + SUPERLATIVE_CONFIG.demo;
    }
    const current_url = new URL(window.location.href);
    const icon_set = current_url.searchParams.get("tp_icon");
    if (icon_set) {
      url += "&icon_set=" + icon_set;
    }
    await superlativesApiCache.initCacheForService(vin_list, url);
    return buildSuperlativeResult(vin_list, superlativePluginLocation);
  }

  function buildSuperlativeResult(items: string[], location: string) {
    const vinData = items.reduce((acc: SuperlativeLookup[], item: string) => {
      if (superlativesApiCache.cache[item]?.result) {
        acc.push(superlativesApiCache.cache[item]?.result);
      }
      return acc;
    }, []);
    return { data: vinData, template_html: superlativesApiCache.templateHtml[location] };
  }

  function get_superlative_name_key(superlative_name: string) {
    return superlative_name.replace(/\s/g, "_").toLowerCase();
  }

  function is_custom_with_sidecar(superlative_info: SuperlativeLookup) {
    return superlative_info.details_superlative.find((detailSuperlative) => {
      return detailSuperlative.show_as_sidecar;
    });
  }

  function get_custom_superlative_title(superlative_info: SuperlativeLookup) {
    const title = superlative_info.details_superlative.find((detailSuperlative) => {
      if (detailSuperlative.show_as_sidecar) {
        return detailSuperlative.title;
      }
    });
    return title;
  }

  function add_superlative_sidecar(superlative_info: SuperlativeLookup, el: HTMLElement) {
    let htmlString;
    if (DEALER_SUPERLATIVE_CONFIG && superlative_info.details) {
      if (is_custom_with_sidecar(superlative_info)) {
        const title = get_custom_superlative_title(superlative_info);
        if (title && title.title && superlative_info.details.superlative !== title.name) {
          htmlString = '<div class="tp-cpo-wrapper"><div class="tp-cpo-sidecar">' + title.title + "</div></div>";
        }
      }
    }

    if (htmlString) {
      const html = domUtils.toElement(htmlString);

      let container;
      if (el.classList.contains("tp-superlative-container")) {
        container = el;
      } else {
        container = el.querySelector(".tp-superlative-container");
      }
      container?.prepend(html);
    }
  }

  function setup_superlative_click(el: HTMLElement, location_config: SuperlativeLocationConfig, superlative_info: SuperlativeLookup) {
    const fbPayload = {
      vin: superlative_info.vin,
      superlative: superlative_info.superlative,
      superlative_id: superlative_info.superlative_id,
    };
    let buildDataWidgetClicked = false;

    const onClickHandler = (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      const target = e.currentTarget as HTMLElement;

      const more_info = target.querySelector<HTMLElement>(".tp-superlative-data");
      const badge_type_el = target.querySelector<HTMLElement>("[data-badge-type]");
      const superlative = more_info.dataset.superlative;
      const superlative_id = more_info.dataset.superlativeId;
      const vin = more_info.dataset.vin;
      const placeholder = more_info.dataset.placeholder;
      const moreInfoDealer = more_info.dataset.dealerId || DEALER_ID;
      let autobioWidgetClicked = false;
      let url;

      if (placeholder) {
        const params = new URLSearchParams({
          vin,
          dealer_id: moreInfoDealer,
          placeholder,
          newtab: "true",
        });

        url = `//${PLUGIN_SERVER}/superlatives/config?${params.toString()}`;
      } else {
        if (!superlative || !vin) {
          console.error("TradePending: Missing superlative/vin that is needed to show more info.");
          return;
        }
        const pluginLocation = getPluginLocation();

        let badgeType = "superlatives";
        let snapAfterType: SnapAfterOptions["type"] = "srp-superlative";
        if (badge_type_el) {
          badgeType = badge_type_el.dataset.badgeType;
        }
        if (badgeType === "autobio") {
          url = `//${PLUGIN_SERVER}/apps/autobio/preview/${moreInfoDealer}/${vin}`;
          snapAfterType = "srp-autobio";
          autobioWidgetClicked = true;
        } else {
          const params = new URLSearchParams({
            vin,
            did: moreInfoDealer,
            location: pluginLocation,
            selectedSuperlative: superlative,
            buildDataEnabled: String(location_config.enable_build_data),
            buildDataExpanded: String(buildDataWidgetClicked),
          }).toString();

          url = `//${PLUGIN_SERVER}/superlatives/superlativesreport?${params}`;
        }
        if (pluginLocation === "srp" && typeof handleSnapAfterAnalytics === "function") {
          handleSnapAfterAnalytics({ forceSend: true, type: snapAfterType, vin });
        }
        buildDataWidgetClicked = false;
      }

      if (placeholder) {
        window.open(url, "_blank");
      } else if (is_mobile) {
        const closeButton = get_close_button();
        const iframe = closeButton + "<iframe id='tradepending-inline-iframe' src='" + url + "' width='100%' frameborder='0' scrolling='no' style='overflow: hidden;'></iframe>";
        launchMobileOverlay(iframe, {
          onClosed: () => {
            logEvent("badge_report_close", { vin, superlative_id, superlative });
          },
        });
      } else {
        let width = "80%";
        const height = "90%";
        if (domUtils.documentWidth() < 1200) width = "90%";

        iframeModal = new IFrameModal(url, { width, height });
        iframeModal.on("closed", () => {
          iframe_height = 0;
          logEvent("badge_report_close", { vin, superlative_id, superlative });
        });
        iframeModal.open();
      }
      // If this was triggered via the build data, don't record additional analytics since those are recorded elsewhere
      // We can assume that if if clientX and clientY are both 0, then the click was triggered by the build data widget
      if (e.clientX > 0 && e.clientY > 0) {
        const key = get_superlative_name_key(superlative);
        let eventPrefix = "";
        if (autobioWidgetClicked) {
          eventPrefix = "autobio_";
        }
        postAnalytics(`moreinfo_${eventPrefix}${key}`);
        logEvent(`${eventPrefix}badge_click`, { vin, superlative_id, superlative });
        fire_ga_event("click", `mi-${eventPrefix}${key}`, autobioWidgetClicked ? "Autobio" : "Superlatives");
      }
    };

    const clickEl = el;

    clickEl.addEventListener("click", onClickHandler);
    const buildDataHtml = clickEl.querySelector<HTMLElement>(".tp-build-data-wrapper");
    buildDataHtml?.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      buildDataWidgetClicked = true;
      logEvent("build_data_click", fbPayload);
      fire_ga_event("click", "bd-" + fbPayload.superlative, "Superlatives");
      return clickEl.click();
    });
  }

  function reportHiddenInsertSelector(el: HTMLElement, selector: string) {
    reportErrorIfHidden(el, "hidden_insert_selector", "Superatives Inserted into hidden element ", selector);
  }

  function reportHiddenNodeSelector(el: HTMLElement, selector: string) {
    reportErrorIfHidden(el, "hidden_node_selector", "Superatives Node Selector returning hidden element ", selector);
  }

  function reportErrorIfHidden(el: HTMLElement, type: string, message: string, selector: string) {
    if (!el) {
      return;
    }
    if (SUPERLATIVE_CONFIG.status !== "working") {
      return;
    }

    if (!domUtils.isVisible(el)) {
      const location = is_mobile ? getPluginLocation() + "_mobile" : getPluginLocation();
      console.error("TradePending: Superlatives Error: type: " + type + ", location: " + location + " message: " + message + ", selector: " + selector);
    }
  }

  function getRootClass() {
    return `tp-superlative-root${isMobile() ? "-mobile" : ""}`;
  }

  function build_superlatives_html(superlative_info: SuperlativeLookup, template_html: string, templateCompiler: TemplateCompiler, locationConfig: SuperlativeLocationConfig) {
    const vin = superlative_info.fetchedVin || superlative_info.vin;
    let html: string;
    const clearDiv = "<div style='clear: both;'></div>";
    const variables = { ...superlative_info, ...locationConfig.variables };
    if (superlative_info.placeholder) {
      html =
        "<div style='text-align:center'><button class='tp-superlative-data' style='padding: 10px; color: #fff; background-color: #f76841; font-size:large;' data-vin='" +
        vin +
        "' data-placeholder=true>Add a superlative</button><div style='font-size:large; font-style:italic'>Only dealers can see this button</div><div>";
    } else if (superlative_info.superlative != "") {
      if (template_html) html = clearDiv + template_html;
      else html = clearDiv + templateCompiler.compile(variables);
    }
    html = `<div class="${getRootClass()}"><div class="tp-superlative-data"></div>${html}</div>`;
    const outputElem = domUtils.toElement(html);
    const others = superlative_info.details_superlative;
    let count = 1;
    if (others && others.length > 1) {
      const icons = outputElem.querySelectorAll<HTMLElement>(".tp-superlative-other-superlatives");
      count = others.length - 1;
      if (superlative_info.details.manager_special) {
        count++;
      }
      icons.forEach((i) => {
        i.append(document.createTextNode("+" + count));
        i.style.display = "flex";
        i.setAttribute("title", "This vehicle has " + count + " more interesting facts.  Click here to learn more!");
      });
    }
    const container = outputElem.querySelector<HTMLElement>(".tp-superlative-container");
    if (container) {
      container.dataset.count = String(count);
    }

    return outputElem;
  }

  function draw_superlative_box(idx: number, superlative_info: SuperlativeLookup, outputContainer: { [vin: string]: HTMLElement }, templateCompiler: TemplateCompiler, location_config: SuperlativeLocationConfig, template_html: string) {
    const element_container = outputContainer[superlative_info.vin];
    if (!element_container) return;
    const vin = superlative_info.fetchedVin || superlative_info.vin;
    const outputElem = build_superlatives_html(superlative_info, template_html, templateCompiler, location_config);

    const insert_selector = location_config.selector;

    const addToElements = domUtils.jqSelect<HTMLElement>(insert_selector, element_container);
    let insert_failed = false;
    reportHiddenInsertSelector(addToElements?.[0], insert_selector);
    if (!addToElements.length) {
      insert_failed = true;
    } else {
      let missingFromParent = true;
      let missingChild = true;
      addToElements.forEach((el) => {
        if (el.parentElement.querySelector(`.${getRootClass()} .tp-superlative-data[data-vin="${vin}"]`)) {
          missingFromParent = false;
        }
        if (el.querySelector(`.${getRootClass()} .tp-superlative-data[data-vin="${vin}"]`)) {
          missingChild = false;
        }
      });

      if (location_config.insert_method === "after" && missingFromParent) {
        addToElements.forEach((el) => el.after(outputElem));
      } else if (location_config.insert_method === "before" && missingFromParent) {
        addToElements.forEach((el) => el.before(outputElem));
      }

      if (location_config.insert_method === "prepend" && missingChild) {
        addToElements.forEach((el) => el.prepend(outputElem));
      } else if ((!location_config.insert_method || location_config.insert_method === "append") && missingChild) {
        addToElements.forEach((el) => el.append(outputElem));
      }

      if (location_config.insert_method === "replaceWith" || location_config.insert_method === "html") {
        addToElements.forEach((el) => domUtils.insertDOMElement(el, location_config.insert_method, outputElem));
      }
    }
    finalize_inserted_element(location_config, superlative_info, element_container, outputElem);
    if (!insert_failed && getPluginLocation() === "vdp") {
      fireSuperlativeVisitAnalytics(superlative_info.superlative);
    }
    return insert_failed;
  }

  function finalize_inserted_element(location_config: SuperlativeLocationConfig, superlative_info: SuperlativeLookup, element_container: HTMLElement, outputElem: HTMLElement) {
    if (superlative_info.superlative) {
      const vin = superlative_info.fetchedVin || superlative_info.vin;
      const dealerId = superlative_info.dealerId || DEALER_ID;
      const moreInfoEls = element_container.querySelectorAll<HTMLElement>(`.tp-superlative-data`);

      moreInfoEls.forEach((moreInfoEl) => {
        moreInfoEl.dataset.vin = vin;
        moreInfoEl.dataset.dealerId = dealerId;
        moreInfoEl.dataset.superlativeId = String(superlative_info.superlative_id);

        if (!moreInfoEl.dataset.superlative) {
          moreInfoEl.dataset.superlative = superlative_info.superlative;
        }
        trackSuperlativeView(moreInfoEl);
      });
    }
    setup_superlative_click(outputElem, location_config, superlative_info);
    add_superlative_sidecar(superlative_info, outputElem);
  }

  function fireSuperlativeVisitAnalytics(superlative: string) {
    if (DEBUG_ENABLED) debugMessage("Firing superlative visit analytics for superlative: ", superlative);
    const fromSRP = isFromSRP() && getPluginLocation() === "vdp";
    if (fromSRP) {
      const key = get_superlative_name_key(superlative);
      fire_ga_event("visit", "vdp_superlative_" + key, "Superlatives");
      postAnalytics("supervisit_" + key);
    }
  }

  // End of SNAP Superlatives

  // Start of snap-after tracking
  interface SnapAfterOptions {
    forceSend?: boolean;
    vin?: string;
    type?: "vdp" | "srp-superlative" | "srp-autobio";
  }
  async function handleSnapAfterAnalytics(options: SnapAfterOptions = {}) {
    if (!VDP_TRACKING_CONFIG) {
      return;
    }
    const pluginLocation = getPluginLocation();
    if (pluginLocation !== "vdp" && !options?.forceSend) {
      return;
    }

    if (options?.vin) {
      _sendSnapAfterEvent({ dealerId: DEALER_ID, vin: options?.vin, eventType: options?.type });
      return;
    }
    if (DDC_API) {
      DDC_API.subscribe("vehicle-shown-v1", (ev) => {
        _sendSnapAfterEvent({ dealerId: DEALER_ID, vin: ev.payload?.vin, eventType: options?.type });
      });

      return;
    }
    const vin_selector = is_mobile ? VDP_TRACKING_CONFIG.vin_selector_mobile : VDP_TRACKING_CONFIG.vin_selector;
    const node_selector = is_mobile ? VDP_TRACKING_CONFIG.node_selector_mobile : VDP_TRACKING_CONFIG.node_selector;

    if (!vin_selector || !node_selector) {
      if (DEBUG_ENABLED) debugMessage("vin_selector and node_selector are not set");
      return;
    }

    const container_node = domUtils.jqSelect(node_selector);

    async function getVins() {
      const vinList: string[] = [];
      let interval = 0;
      const sleepTime = 500;

      while (vinList.length === 0 && interval < 5) {
        await sleep(sleepTime * interval++);
        container_node.forEach((element) => {
          const result = getDataFromElement(element, vin_selector, ExtractedItem.VIN);
          if (result) vinList.push(result);
        });
      }
      return vinList;
    }

    const vin_list = await getVins();

    if (vin_list.length === 0) {
      if (DEBUG_ENABLED) debugMessage("No VIN found");
    } else if (vin_list.length > 1) {
      if (DEBUG_ENABLED) debugMessage("Found more than 1 VIN on VDP");
    }
    const vin = vin_list.join(",");
    _sendSnapAfterEvent({ dealerId: DEALER_ID, vin, eventType: options?.type });
  }

  const buildUtmObject = (): { [key: string]: string } => {
    const url = window.location.href;
    const utm = {};
    if (url.match(/utm/i)) {
      const regex = /utm_?([^=]*)=([^&]*)/gi;
      let match = regex.exec(url);
      while (!!match) {
        utm[match[1].replace(/\./g, "_")] = match[2];
        match = regex.exec(url);
      }
    }
    return utm;
  };
  async function _sendSnapAfterEvent({ dealerId, vin, eventType }: { dealerId: string; vin: string; eventType?: string }) {
    const params: { [key: string]: string } = {
      dealer_id: dealerId,
      host_url: window.location.href,
      vin,
      timestamp: String(new Date().getTime()),
    };

    if (eventType) {
      params.type = eventType;
    }
    const r = document.cookie.match(/tpa_vdp=(.*?)(?:;|$)/);
    if (r) {
      params.survey_id = r[1];
    }

    if (linkPromise) {
      await linkPromise; // guarantee cookies have been set
    }
    const autobioVdp = document.createElement("script");
    const tplink = getCookie(`tp_link`);
    if (tplink) {
      params.lid = tplink;
    }
    const queryStr = new URLSearchParams(params);

    const utm = buildUtmObject();

    for (const key of Object.keys(utm)) {
      queryStr.append(`utm[${key}]`, utm[key]);
    }

    const abUrl = `${PLUGIN_AJAX_URL}/survey/${dealerId}/${vin}/vdp.js?${queryStr.toString()}`;
    autobioVdp.setAttribute("src", abUrl);
    autobioVdp.setAttribute("async", "true");
    document.body.appendChild(autobioVdp);
  }

  // End of snap-after tracking

  let superlatives_installed = false;
  const is_mobile = isMobile();
  let plugin_page_locale = null;
  let snap_widget_installed = false;
  let vrp_ready_listener_installed = false;
  let conversion_data = null;
  let tp_analytics_session_id = null;
  let tp_analytics_session_id_is_new = false;
  let iframe_height = 0;
  let conversion_lead_id = null;
  const analytics_queue: any[] = [];
  let analytics_lock = null;
  let showing_mobile_overlay = false;

  function receiveMessage(event) {
    if (!PLUGIN_AJAX_URL.includes(event.origin) && !AUTOAPR_APP_URL.includes(event.origin) && !event.origin?.includes("localhost")) {
      if (event.origin.substring(event.origin.indexOf(":")) !== PLUGIN_AJAX_URL.substring(PLUGIN_AJAX_URL.indexOf(":")) && event.origin.substring(event.origin.indexOf(":")) !== AUTOAPR_APP_URL.substring(AUTOAPR_APP_URL.indexOf(":"))) {
        return;
      }
    }
    let data;
    if (typeof event.data === "string") {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }
    if (DEBUG_ENABLED) debugMessage("receiveMessage: ", data);

    if (DEBUG_ENABLED) debugMessage("Show close button?: ", data.show_close_button);

    if (typeof data.show_close_button === "boolean") {
      const closeButton = ".tradepending-close-affix";
      if (data.show_close_button) {
        domUtils.show(closeButton);
      } else {
        domUtils.hide(closeButton);
      }
    }
    if (data.height !== undefined) {
      const selector = data.selector || "iframe#tradepending-inline-iframe";
      if (DEBUG_ENABLED) debugMessage("resizing for selector: ", selector);

      if (is_mobile) {
        // it has become exceedingly difficult to consistently get the window height of the parent from within the iframe
        // so get it here, and use the min value to set the iframe height.
        let pixels = data.height;
        if (typeof pixels === "string") {
          pixels = Number(pixels.replace("px", "").trim());
        }

        const isLanding = tpUtil.isLandingOrInline();
        const height = isLanding ? `${Math.min(pixels, Math.floor(window.innerHeight * 0.65))}px` : pixels;
        if (DEBUG_ENABLED) debugMessage("receiveMessage is updating inline-iframe height to: " + height);

        domUtils.jqSelect<HTMLElement>(selector).forEach((n) => {
          n.style.height = height;
        });

        if (iframeModal) {
          iframeModal.resize({ height });
        }
      } else {
        if (DEBUG_ENABLED) debugMessage("receiveMessage is updating height to: " + data.height);
        if (iframe_height < data.height || data.force_height) {
          const height = `${data.height}px`;
          iframe_height = data.height;
          document.querySelector(selector).style.height = height;

          if (iframeModal) {
            iframeModal.resize({ height });
          }
        }
      }
    }

    if (data.asc_event !== undefined) {
      switch (data.asc_event?.action) {
        case "form_start":
          fire_asc_formstart_events(data.asc_event?.product, data.asc_event.vehicle);
          break;
        case "form_submit":
          fire_asc_cta_event({ product: data.asc_event?.product, actionResult: "success", elementText: "submit", vehicle: data.asc_event.vehicle });
          fire_asc_formsubmission_events(data.asc_event?.product, data.asc_event.vehicle);
          break;
      }
    }
    if (data.ga_event !== undefined) {
      fire_ga_event(data.ga_event.action, data.ga_event.label, data.ga_event?.product || "SNAP");
      if (data.ga_event?.product === "SNAP" || data.ga_event?.product === "Trade") {
        if (data.ga_event?.eventName) {
          fire_ga_event(data.ga_event.eventName, undefined, undefined, data.ga_event?.gaParams);
        }
      }
    }

    if (data.firebase_event !== undefined) {
      logEvent(data.firebase_event.action, data.firebase_event.params);
    }

    if (SUPERLATIVE_CONFIG) {
      if (DEBUG_ENABLED) debugMessage("receiveMessage updating allow_superlative_config to" + data.allow_superlative_config);
      if (data.allow_superlative_config) {
        SUPERLATIVE_CONFIG.allow_config = true;
        SUPERLATIVE_CONFIG.user_id = data.user_id;
        SUPERLATIVE_CONFIG.demo = data.demo;
      } else {
        SUPERLATIVE_CONFIG.allow_config = false;
      }
    }
    if (data.event === "selected") {
      fire_vehicle_selected_events(data.vehicle, data.product);
    } else if (data.event === "lead-form") {
      if (PLUGIN_CONFIG.program_oem === "fca" && typeof window.digitalData === "object" && typeof window.digitalData.newEvent === "function") {
        if (DEBUG_ENABLED) debugMessage("Firing FCA analytics start event");
        window.digitalData.newEvent({
          type: "CustomTagEvent",
          eventName: "start",
          eventAction: "form",
          attributes: { formDescription: "lead", formType: "vendor trade in tool", displayType: "inPage", displayFormat: "iframe", tradeInProvider: "TradePending" },
        });
      }
      if (PLUGIN_CONFIG.program_oem === "gm" && typeof window._satellite === "object" && typeof window._satellite.track === "function") {
        if (DEBUG_ENABLED) debugMessage("Firing GM analytics start event on parent page");
        window._satellite.track("contact-form");
      }
    } else if (data.event === "additional-details") {
      if (PLUGIN_CONFIG.program_oem === "gm" && typeof window._satellite === "object" && typeof window._satellite.track === "function") {
        if (DEBUG_ENABLED) debugMessage("Firing GM analytics additional-details event");
        window._satellite.track("additional-details");
      }
    } else if (data.event === "selected_year" && is_mobile && tpUtil.isLandingOrInline()) {
      fire_tradein_start_events("mobile_inline");
    }

    if (data.conversion !== undefined) {
      if (DEBUG_ENABLED) debugMessage("Received conversion event", data.conversion);
      if (window.tradependingConversionEvent !== undefined && typeof window.tradependingConversionEvent === "function") {
        window.tradependingConversionEvent(data.conversion);
      }
      if (data.conversion.product === "trade" && PLUGIN_CONFIG.program_oem === "gm" && typeof window._satellite === "object" && typeof window._satellite.track === "function") {
        if (DEBUG_ENABLED) debugMessage("Firing GM analytics conversion event");
        window._satellite.track("market-report");
      }
      if (PLUGIN_CONFIG.autoleadstar_enabled && typeof window.AutoLeadStarEvent === "function") {
        if (DEBUG_ENABLED) debugMessage("Firing AutoLeadStar analytics conversion event", data.conversion);
        window.AutoLeadStarEvent(3810, "conversion", data.conversion);
      }
      if (PLUGIN_CONFIG.roiq_enabled && typeof window.trafficscore?.track?.track === "function" && window.traffic_score_data?.account_id) {
        const roiq_data = {
          firstname: data.conversion.first_name,
          lastname: data.conversion.last_name,
          email: data.conversion.email,
          phone: data.conversion.phone,
        };
        if (DEBUG_ENABLED) debugMessage("Firing RoiQ analytics conversion event to account: ", roiq_data);
        window.trafficscore.track.track(window.traffic_score_data.account_id, "LEAD_CONVERSION", null, roiq_data, true);
      }
      conversion_data = data.conversion;
    }
    if (data.conversion_lead_id) {
      conversion_lead_id = data.conversion_lead_id;
      // Create cookie to store lead ID for AutoAPR
      const maxage = 1 * 24 * 60 * 60;
      document.cookie = "tp_snap_lead_id=" + conversion_lead_id + "; max-age=" + maxage + ";";
    }
    if (data.conversion_lead_id !== undefined && conversion_data != null && PLUGIN_CONFIG.program_oem === "fca" && typeof window.digitalData == "object" && typeof window.digitalData.newEvent === "function") {
      const leadId = data.conversion_lead_id + ":" + data.conversion_timestamp;
      if (DEBUG_ENABLED) debugMessage("Firing FCA analytics conversion event with lead_id: " + leadId + " and data: ", conversion_data);
      window.digitalData.newEvent({
        type: "CustomTagEvent",
        eventName: "submit",
        eventAction: "form",
        attributes: {
          formDescription: "lead",
          formType: "vendor trade in tool",
          leadId,
          displayType: "inPage",
          displayFormat: "iframe",
          tradeInProvider: "TradePending",
          tradeInVehicleYear: conversion_data.year,
          tradeInVehicleMake: conversion_data.make,
          tradeInVehicleModel: conversion_data.model,
          desiredVehicleYear: conversion_data.interest_vehicle_year,
          desiredVehicleMake: conversion_data.interest_vehicle_make,
          desiredVehicleModel: conversion_data.interest_vehicle_model,
        },
      });
      conversion_data = null; // clear it out so we don't double fire
    }

    if (data.aa_event) {
      if (DEBUG_ENABLED) debugMessage("Received AutoAPR event", data.aa_event.action);
      // Send AutoAPR events to TP Analytics
      postAnalytics(data.aa_event.action);
      if (PLUGIN_CONFIG.full_js_integration && data.aa_event.action && window.tradependingPaymentsStep !== undefined && typeof window.tradependingPaymentsStep === "function") {
        const productStep = data.aa_event.action.split("_");
        if (DEBUG_ENABLED) debugMessage("Calling js method: tradependingPaymentsStep for product: " + productStep[1] + " and step: " + productStep[2]);
        window.tradependingPaymentsStep(getProductFullName(productStep[1]), productStep[2]);
      }
    }

    if (data.cta_event) {
      if (DEBUG_ENABLED) debugMessage("Received CTA event", data.cta_event.action);
      // e.g. epr_start, epr_submit
      postAnalytics(data.cta_event.action);
    }

    if (PLUGIN_CONFIG.full_js_integration && data.vehicle !== undefined && data.pricing !== undefined && window.tradependingReportRun !== undefined && typeof window.tradependingReportRun === "function") {
      if (DEBUG_ENABLED) debugMessage("Calling js method: tradependingReportRun with vehicle: " + data.vehicle.ymmt + " and trade-in value: $" + data.pricing?.tradeid?.low + " - $" + data.pricing?.tradein?.high);
      reportInfo("feature-usage", { feature: "tradependingReportRun" });
      window.tradependingReportRun(data.vehicle, data.pricing);
    } else if (PLUGIN_CONFIG.full_js_integration && data.vehicle !== undefined && window.tradependingVehicleSelected !== undefined && typeof window.tradependingVehicleSelected === "function") {
      if (DEBUG_ENABLED) debugMessage("Calling js method: tradependingVehicleSelected with vehicle: " + data.vehicle.ymmt);
      reportInfo("feature-usage", { feature: "tradependingVehicleSelected" });
      window.tradependingVehicleSelected(data.vehicle);
    }
  }

  function reportError(type: string, params: any) {
    console.error("TradePending: SNAP Report Error: type: " + type + ", location: " + getPluginLocation() + " params: ", params);
    console.trace();
    sendLog("error", type, params);
  }

  async function sendLog(level: "error" | "info", type: string, params: any) {
    params.plugin_id = PLUGIN_ID;
    params.host_url = window.location.origin + window.location.pathname + encodeURIComponent(window.location.hash);
    const path = level === "info" ? "/plugin-log/" : "/plugin-error/";
    await fetch(PLUGIN_AJAX_URL + path + type, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(params),
    });
  }

  function reportInfo(type: string, params: any) {
    sendLog("info", type, params);
  }

  async function notifyClosed() {
    maybeShowSurvey();
    if (DEBUG_ENABLED) debugMessage("notifyClosed called with conversion_lead_id set to: " + conversion_lead_id);
    if (conversion_lead_id) {
      await fetch("//" + PLUGIN_SERVER + "/close?lead_id=" + conversion_lead_id);
    }
  }

  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      notifyClosed();
    }
  });

  // selector can be something like
  // @data-vehicle-vin - grab data-vehicle-vin attribute off the element
  // span#vin - grab the innerHTML of the span with id vin within the element
  // div#detailspage@data-vin - grab the data-vin attribute off the div with id detailspage within the element
  function getDataFromElement(element: Element | undefined, data_selector_raw: string, extracted_item: ExtractedItem) {
    if (!element) {
      return;
    }

    let dataAttribute: string;
    let dataElement = element;
    if (data_selector_raw.startsWith("@")) {
      dataAttribute = data_selector_raw.substring(1);
    } else if (data_selector_raw.indexOf("@") > 0) {
      const selector = data_selector_raw.substring(0, data_selector_raw.indexOf("@"));
      dataAttribute = data_selector_raw.substring(data_selector_raw.indexOf("@") + 1);
      dataElement = element.querySelector(selector);
    } else {
      dataElement = element.querySelector(data_selector_raw);
    }

    if (!dataElement) {
      return null;
    }
    if (dataAttribute && !dataAttribute.startsWith("data-")) {
      dataAttribute = `data-${dataAttribute}`;
    }
    if (dataAttribute) {
      const dataValue = dataElement.getAttribute(dataAttribute);
      if (!dataValue) {
        debugMessage("TradePending: Could not find vin (try a more restrictive node selector or a different vin selector) :", dataAttribute, data_selector_raw, element);
        return;
      }
      return extractDataValue(dataValue, extracted_item);
    } else {
      return extractDataValue(dataElement.innerHTML, extracted_item); // Only have a node selector
    }
  }

  function extractDataValue(data: string, extracted_item: ExtractedItem) {
    switch (extracted_item) {
      case ExtractedItem.VIN: {
        const vinPattern = AUTOAPR_CONFIG?.locations[getPluginLocation()]?.use_custom_vin_pattern ? AUTOAPR_CONFIG.locations[getPluginLocation()].custom_vin_pattern : "[0-9A-Z]{17}";
        return extractVinOrStockNumber(data, vinPattern);
      }
      case ExtractedItem.STOCK_NUMBER:
        return extractVinOrStockNumber(data, AUTOAPR_CONFIG?.locations[getPluginLocation()]?.stock_numbers_pattern);
      case ExtractedItem.PRICE:
        return extractPrice(data);
    }
  }

  function extractVinOrStockNumber(data: string, pattern: string) {
    if (!data) {
      if (DEBUG_ENABLED) debugMessage("No VIN / Stock Number found, the element passed to the extraction function was empty");
      return null;
    }
    let regex_result = data.match(pattern);
    if (regex_result != null) {
      return regex_result[0];
    } else {
      regex_result = data.match(pattern.toLocaleLowerCase());
      if (regex_result != null) {
        return regex_result[0].toUpperCase();
      }
      if (DEBUG_ENABLED) debugMessage("No VIN / Stock Number found in ", data);
      return null;
    }
  }

  function extractPrice(price: string) {
    if (!price) {
      if (DEBUG_ENABLED) debugMessage("No PRICE found, the element passed to the extraction function was empty");
      return null;
    }
    if (price.indexOf(".") > 0) {
      price = price.substring(0, price.indexOf("."));
    }
    return price.replace(/[^0-9]/g, "");
  }

  function addVdpParams(plugin: SnapLocationData | {}, params: any) {
    if (plugin && "ymm_selector" in plugin && typeof plugin?.ymm_selector !== "undefined" && typeof plugin?.ymm_regex !== "undefined") {
      try {
        if (DEBUG_ENABLED) debugMessage("Looking for VDP params using selector: " + plugin.ymm_selector + " and regex: " + plugin.ymm_regex);
        const ymm_regex = plugin.ymm_regex.replace(new RegExp(/\\\\/g), "\\");
        const ymmText = domUtils.text(plugin.ymm_selector);
        const ymm = ymmText.match(ymm_regex).slice(1);
        params.vdp_ymm = ymm.join(";");
      } catch (ex) {
        reportError("general", { type: "extract_ymm", message: "Unable to extract ymm from VDP. ex: " + ex.message });
      }
    }
    if (plugin && "dealership_loc_selector" in plugin && (plugin?.dealership_loc_selector || plugin?.url_lead_routing)) {
      try {
        if (plugin.url_lead_routing) {
          if (DEBUG_ENABLED) debugMessage("Attempting automatic dealership routing with URL Regex: " + plugin.url_lead_routing);
          if (window.location.pathname.match(plugin.url_lead_routing)) {
            params.dealership_location = plugin.url_lead_routing;
            if (DEBUG_ENABLED) debugMessage("Found matching URL info for: " + plugin.url_lead_routing);
          }
        } else if (plugin.dealership_loc_selector) {
          if (DEBUG_ENABLED) debugMessage("Attempting automatic dealership routing with css selector: " + plugin.dealership_loc_selector);
          const elements = domUtils.jqSelect<HTMLElement>(plugin.dealership_loc_selector);
          let dealership_loc: string;
          if (typeof plugin.dealership_loc_attribute !== "undefined" && elements.length) {
            if (DEBUG_ENABLED) debugMessage("Using dealership_loc_attribute of: " + plugin.dealership_loc_attribute);
            dealership_loc = elements[0].getAttribute(plugin.dealership_loc_attribute);
          } else {
            dealership_loc = elements.reduce((acc, el) => acc + el.innerText, "");
          }
          dealership_loc = dealership_loc
            .replace(/(\r\n|\n|\r)/gm, " ")
            .replace(/\s\s+/g, " ")
            .trim();
          if (dealership_loc != null && dealership_loc.length > 0) {
            if (DEBUG_ENABLED) debugMessage("Sending dealership location info: " + dealership_loc + " for lead routing");
            params.dealership_location = dealership_loc;
          } else {
            if (DEBUG_ENABLED) debugMessage("Did not find dealership location info for lead routing");
          }
        }
      } catch (ex) {
        reportError("general", { type: "extract_dealer", message: "Unable to extract dealership location from VDP. ex: " + ex.message });
      }
    }
    params.host_url = window.location.href;
    return params;
  }

  const maybeShowSurvey = () => {
    if (conversion_data?.first_name && COLLECT_SURVEY && !conversion_data?.email.includes("@tradepending.com")) {
      (function (t, e, s, o) {
        let n;
        let c;
        let l;
        (t.SMCX = t.SMCX || []),
          e.getElementById(o) ||
            ((n = e.getElementsByTagName(s)),
            (c = n[n.length - 1]),
            (l = e.createElement(s)),
            (l.type = "text/javascript"),
            (l.async = !0),
            (l.id = o),
            (l.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd0K52vITHjEF2LVlKcIwmy0L5HFPt4q9GbMl0Y88sq_2Bs.js"),
            c.parentNode.insertBefore(l, c));
      })(window, document, "script", "smcx-sdk");
    }
  };

  function launchContactForm(
    vehicle: {
      year: string;
      make: string;
      model: string;
      trim: string;
    },
    options = { form: undefined, height: undefined, top: undefined, fixed: undefined, is_value_tracker: undefined }
  ) {
    const plugin_location = getPluginLocation();
    const plugin = PLUGIN_DATA[plugin_location];
    const params = {
      year: vehicle.year,
      make: vehicle.make,
      model: vehicle.model,
      trim: vehicle.trim,
      did: PLUGIN_ID,
      loc: plugin_location,
      ref: "widget",
      is_value_tracker: options.is_value_tracker,
    };
    if (!vehicle.trim) {
      delete params.trim;
    }
    addVdpParams(plugin, params);
    let route = "vehicles";
    if (vehicle.model && vehicle.make && vehicle.model && vehicle.trim) {
      route = "contact";
    }
    let url = `//${PLUGIN_SERVER}/${route}?${new URLSearchParams(params).toString()}`;
    if (DEBUG_ENABLED) debugMessage("Launch TradePending Overlay at: ", url);
    let width = "80%";
    if (domUtils.windowWidth() < 1200) width = "95%";

    if (options.form) url = url + "&contact_form=" + options.form;
    url = tpUtil.tp_decorate(url);
    url = tpUtil.referer_decorate(url);
    url = tpUtil.ga_decorate(url);
    url = tpUtil.shift_decorate(url);
    url = tpUtil.tealium_decorate(url);
    if (plugin_page_locale != null) url += "&locale=" + plugin_page_locale;

    let height;
    if (options.height) height = options.height;
    else height = "90%";

    window.addEventListener(
      "message",
      function (e) {
        if (typeof e.data === "string") {
          const payload = JSON.parse(e.data);
          if (payload.name === "update_tpa_vdp") {
            document.cookie = "tpa_vdp=" + payload.value + "; max-age=" + 90 * 24 * 60 * 60 + ";";
          }
        }
      },
      false
    );

    if (isMobile()) {
      window.open(url, "snapwindow").focus();
    } else {
      iframeModal = new IFrameModal(url, { width, height, top: options.top, fixed: options.fixed });
      iframeModal.on("cleanup", maybeShowSurvey);
      iframeModal.on("closed", notifyClosed);
      iframeModal.open();
    }

    if (typeof window._pxam === "object" && PLUGIN_CONFIG.ddc_tracking) {
      if (DEBUG_ENABLED) debugMessage("Firing DDC step tracking event");
      window._pxam.push({
        type: "event",
        eventType: "step",
        eventSource: "trade-in-offer",
        feature: "TradePending",
        label: plugin_location,
      });
    }
  }

  function buildVehicleSelectFrame(options: {
    popup_initiator?: string;
    plugin: SnapLocationData | { url_constructor?: (vin: string) => string; untie_stock_number_from_vin?: boolean; stock_number?: string; additional_params?: { internal_usage: boolean }; vin?: string };
    is_mobile?: boolean;
    is_mobile_overlay?: boolean;
    show_close_button?: boolean;
    classes?: string;
    is_value_tracker?: boolean;
  }) {
    const params = {
      did: PLUGIN_ID,
      loc: getPluginLocation(),
      mobile_inline: String(true),
      locale: undefined,
      mobile: undefined,
      mobile_overlay: undefined,
      ref: options.popup_initiator || "mobile_widget",
      is_value_tracker: String(options.is_value_tracker || false),
    };
    addVdpParams(options.plugin, params);
    if (plugin_page_locale != null) params.locale = plugin_page_locale;
    if (location.search.length > 0) {
      const incoming_params = parse_query_params();
      Object.keys(incoming_params).forEach((key) => {
        if (key.startsWith("tpdata_")) {
          params[key.substring(7)] = decodeURIComponent(incoming_params[key]);
        }
      });
    }
    params.mobile = options.is_mobile;
    params.mobile_overlay = !!options.is_mobile_overlay;
    let url = `//${PLUGIN_SERVER}/vehicles?${new URLSearchParams(params).toString()}`;
    // enable alternative url scheme for autoapr requests
    if ("url_constructor" in options.plugin && options.plugin?.url_constructor) {
      let baseUrl = options.plugin.url_constructor(options.plugin?.untie_stock_number_from_vin ? options.plugin.stock_number : options.plugin.vin);
      if (baseUrl.startsWith("//")) {
        baseUrl = window.location.protocol + baseUrl;
      }
      const parsedUrl = new URL(baseUrl);
      for (const [key, value] of Object.entries(params)) {
        if (!parsedUrl.searchParams.has(key)) {
          parsedUrl.searchParams.append(key, value);
        }
      }

      url = parsedUrl.toString();
    }
    if (DEBUG_ENABLED) debugMessage("iframe url: " + url);
    url = tpUtil.tp_decorate(url);
    url = tpUtil.referer_decorate(url);
    url = tpUtil.vehicle_decorate(url, options.plugin);
    url = tpUtil.ga_decorate(url);
    url = tpUtil.tealium_decorate(url);
    if (!url.startsWith("https:")) {
      url = `https:${url}`;
    }
    let close_button = "";
    if (options.show_close_button) {
      close_button = get_close_button();
    }
    let classes = "";
    if (options.classes) {
      classes = "class=" + options.classes;
    }
    return close_button + "<iframe id='tradepending-inline-iframe' " + classes + " src='" + url + "' width='100%' frameborder='0' scrolling='auto' style='overflow: hidden;' allow='otp-credentials'></iframe>";
  }

  function get_close_button() {
    return "<button id='cboxTPClose' class='tradepending-close-affix' role='button' aria-label='close'>&times;</button>";
  }

  function launchColorboxFullProcessPopup(plugin_config, popup_initiator?: string) {
    const plugin_location = getPluginLocation();
    const plugin = PLUGIN_DATA[plugin_location];
    let params = addVdpParams(plugin, {});
    params.location = plugin_location;
    if (plugin_page_locale != null) params.locale = plugin_page_locale;

    const paramsObj = new URLSearchParams(params).toString();
    let url = `//${PLUGIN_SERVER}/snap/iframe/${PLUGIN_ID}?${paramsObj}`;
    // enable alternative url scheme for autoapr/ctas requests
    if (plugin_config?.url_constructor) {
      if (plugin_config.additional_params) {
        params = { ...params, ...plugin_config.additional_params };
      }
      const baseUrl = plugin_config.url_constructor(plugin_config?.untie_stock_number_from_vin ? plugin_config.stock_number : plugin_config.vin);
      const queryParams = new URLSearchParams(params).toString();
      url = `${baseUrl}${baseUrl.includes("?") ? "&" : "?"}${queryParams}`;
    }
    url = tpUtil.tp_decorate(url);
    url = tpUtil.referer_decorate(url);
    url = tpUtil.vehicle_decorate(url, plugin_config);
    url = tpUtil.ga_decorate(url);
    url = tpUtil.tealium_decorate(url);
    if (popup_initiator) {
      url += `&ref=${popup_initiator}`;
    }
    let width = "80%";
    const height = "90%";
    if (domUtils.documentWidth() < 1200) width = "90%";

    iframeModal = new IFrameModal(url, { width, height });
    iframeModal.on("closed", notifyClosed);
    iframeModal.open();

    if (typeof window._pxam === "object" && plugin_config.ddc_tracking) {
      if (DEBUG_ENABLED) debugMessage("Firing DDC step tracking event");
      window._pxam.push({
        type: "event",
        eventType: "step",
        eventSource: "trade-in-offer",
        feature: "TradePending",
        label: plugin_location,
      });
    }
  }

  let ga_object = null;

  function get_ga_object() {
    if (ga_object) {
      return ga_object;
    }
    if (typeof window.ga === "function" && typeof window.ga.getAll === "function" && Array.isArray(window.ga.getAll())) {
      ga_object = window.ga;
    } else {
      const ga_object_name = Object.keys(window).find((key) => typeof window[key] === "function" && typeof window[key].getAll === "function" && Array.isArray(window[key].getAll()));
      if (ga_object_name) {
        if (DEBUG_ENABLED) debugMessage("TradePending found GA object under name: ", ga_object_name);
        ga_object = window[ga_object_name];
      }
    }
    if (!ga_object && typeof window.ga === "function") {
      //we don't set it to be our global ga_object since this is just the temporary ga object to hold events sent before ga fully loads
      return window.ga;
    }
    return ga_object;
  }

  function getGaTracker(requested_ga_id) {
    if (DEBUG_ENABLED) debugMessage("getGaTracker: requested_ga_id: " + requested_ga_id);
    const gao = get_ga_object();
    if (gao?.getAll && typeof gao.getAll === "function" && gao.getAll().length > 0) {
      for (let i = 0; i < gao.getAll().length; i++) {
        const maybe = gao.getAll()[i];
        if (typeof maybe === "object") {
          const maybe_ga_id = maybe.get("trackingId");
          if (DEBUG_ENABLED) debugMessage("Check if tracker with ga_id: " + maybe_ga_id + " = requested ID: " + requested_ga_id);
          if (requested_ga_id.toString().trim() === maybe_ga_id?.toString().trim()) {
            if (DEBUG_ENABLED) debugMessage("Found tracker that matches ga_id: " + requested_ga_id);
            return maybe;
          }
        }
      }
      if (DEBUG_ENABLED) debugMessage("No GA tracker found that matches " + requested_ga_id);
    }
    return null;
  }

  function fire_asc_cta_event({ product, actionResult, vehicle, elementText }: { product: string; actionResult: string; vehicle?: any; elementText: string }) {
    const vehicleParams = formatVehicle(vehicle);
    const ctaParams = { element_text: elementText?.toLowerCase(), event_action_result: actionResult };
    fire_ga_event("asc_cta_interaction", undefined, product, product, { ...ctaParams, ...vehicleParams }, { isAscEvent: true });
  }

  function fire_asc_formstart_events(product: string, vehicle?: any) {
    const vehicleParams = formatVehicle(vehicle);
    const formParams = { department: "sales", comm_status: "start", comm_type: "form", form_name: product, form_type: "trade" };
    fire_ga_event("asc_form_engagement", undefined, product, product, { ...formParams, ...vehicleParams }, { isAscEvent: true });
  }
  function fire_asc_formsubmission_events(product: string, vehicle?: any) {
    const vehicleParams = formatVehicle(vehicle);
    const formParams = { department: "sales", comm_status: "crm_update", comm_type: "form", form_name: product, form_type: "trade" };
    fire_ga_event("asc_form_submission", undefined, product, product, { ...formParams, ...vehicleParams }, { isAscEvent: true });
    fire_ga_event("asc_form_engagement", undefined, product, product, { ...formParams, ...vehicleParams }, { isAscEvent: true });
    fire_ga_event("asc_form_submission_sales", undefined, product, product, { ...formParams, ...vehicleParams }, { isAscEvent: true });
  }

  function formatVehicle(vehicle: any) {
    let condition = "unknown";

    switch (vehicle?.condition) {
      case VehicleCondition.USED:
        condition = "used";
        break;
      case VehicleCondition.NEW:
        condition = "new";
        break;
      case VehicleCondition.CERTIFIED:
        condition = "cpo";
        break;
    }
    return {
      item_id: vehicle?.vin,
      item_number: vehicle?.stock_number,
      item_price: vehicle?.price,
      item_condition: condition,
      item_year: vehicle?.year,
      item_make: vehicle?.make,
      item_model: vehicle?.model,
      item_variant: vehicle?.trim,
    };
  }

  function fire_ga_event(action: string, label?: string, category?: string, product?: string, eventParams?: {}, meta?: { isAscEvent?: boolean }) {
    let msg;
    if (DEBUG_ENABLED) {
      msg = "fire_ga_event: " + action;
      if (label != null) msg += " --> " + label;
    }
    if (!window.gtag && window.dataLayer) {
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag("js", new Date());
      if (PLUGIN_CONFIG.ga4_tracker_id) {
        const ids = PLUGIN_CONFIG.ga4_tracker_id.split(",").map((s) => s.trim());
        for (const id of ids) {
          window.gtag("config", id);
        }
      }
    }

    if (window.gtag) {
      let translatedCategory = category || product;
      switch (category) {
        case "SNAP":
          translatedCategory = "Trade";
          break;
        case "Superlatives":
          translatedCategory = "Badges";
          break;
      }

      const isAscEvent = meta?.isAscEvent;
      const event_message: string = isAscEvent ? action : "tp_" + action;
      const eventData: any = {
        ...eventParams,
        company: "TradePending",
        event_action: action,
        event_label: label,
        event_category: translatedCategory,
        event_owner: "tradepending",
      };
      if (product) {
        eventData.product = product;
      }

      // If a site is using GTM to pull down gtag.js, they probably never have configured the gtag function. So, an explicit tracker id is needed.
      if (PLUGIN_CONFIG.ga4_tracker_id) {
        const ids = PLUGIN_CONFIG.ga4_tracker_id.split(",").map((s) => s.trim());
        eventData.send_to = ids.length > 1 ? ids : ids[0];
      }
      window.gtag("event", event_message, eventData);
    }

    let oldCategory = category;
    switch (category) {
      case "Trade":
        oldCategory = "SNAP";
        break;
      case "Badges":
        oldCategory = "Superlatives";
        break;
    }
    const gao = get_ga_object();
    if (gao?.getAll && typeof gao.getAll === "function") {
      const submitted_ids = {};
      if (DEBUG_ENABLED) debugMessage("Firing GA event: " + action + ", " + label + ", " + oldCategory);
      gao.getAll().forEach(function (tracker) {
        const ga_tracker_name = tracker.get("name");
        const ga_tracking_id = tracker.get("trackingId");
        if (!submitted_ids[ga_tracking_id]) {
          submitted_ids[ga_tracking_id] = ga_tracking_id;
          gao(ga_tracker_name + ".send", "event", oldCategory, action, label);
          if (DEBUG_ENABLED) debugMessage(msg + " to GA ID: " + ga_tracking_id + " on tracker: " + ga_tracker_name);
          if (PLUGIN_CONFIG.vistadash_customer != null && action === "lead-form") gao(ga_tracker_name + ".send", "event", "CPE-TradePending-Start");
          if (PLUGIN_CONFIG.vistadash_customer != null && action === "submit") gao(ga_tracker_name + ".send", "event", "CPE-Trade-In-Complete");
        } else {
          if (DEBUG_ENABLED) debugMessage("Duplicate GA Tracking ID, not firing event for: " + msg + " to GA ID: " + ga_tracking_id + " on tracker: " + ga_tracker_name);
        }
      });
    } else {
      if (DEBUG_ENABLED) debugMessage("TradPending: Unable to discover global GA object so can't fire_ga_event");
    }
    if (action === "submit" && PLUGIN_CONFIG.adwords_id != null && PLUGIN_CONFIG.adwords_label != null) {
      document.body.insertAdjacentHTML(
        "beforeend",
        '<div style="display:inline;"><img height="1" width="1" style="border-style:none;" alt="" src="//www.googleadservices.com/pagead/conversion/' +
          PLUGIN_CONFIG.adwords_id +
          "/?label=" +
          PLUGIN_CONFIG.adwords_label +
          '&amp;guid=ON&amp;script=0">'
      );
    }
  }

  function launchMobileOverlay(iframe, options?: { onClosed?: () => void }) {
    if (showing_mobile_overlay) {
      return;
    }
    showing_mobile_overlay = true;

    const body = document.body;
    const selector = ".tp-mobile-overlay";
    const el = domUtils.toElement("<div class='tp-mobile-overlay'>" + iframe + "</div>");

    el.classList.add("tp-mobile-overlay-slidedown");
    body.appendChild(el);
    el.style.overflow = "scroll";
    body.style.overflow = "hidden";

    const setIframeHeight = () => {
      const dom = el;
      let iframeEl = dom;
      if (dom?.tagName !== "IFRAME") {
        iframeEl = dom.querySelector("iframe");
      }

      if (iframeEl) {
        iframeEl.style.height = window.innerHeight + "px";
      }
    };
    setIframeHeight();
    window.addEventListener("resize", setIframeHeight); // resize can occur when mobile URL bar is shown/hides

    document.querySelector(".tradepending-close-affix")?.addEventListener("click", (event) => {
      el.classList.add("tp-mobile-overlay-slideup");
      setTimeout(() => {
        domUtils.remove(selector);
        domUtils.remove(".tradepending-close-affix");
        body.style.overflow = "visible";
        notifyClosed();
        if (options?.onClosed) {
          options.onClosed();
        }
        showing_mobile_overlay = false;
      }, 400);
      window.removeEventListener("resize", setIframeHeight);
    });
  }

  function isFromSRP() {
    const path = document.referrer && document.referrer.split("/")[3];
    const srp = PLUGIN_LOCATION_CONFIGS["srp"];
    let match = false;
    if (srp && path) {
      const regexes = [srp.url_regex, srp.custom_url_regex];
      for (let index = 0; index < regexes.length; index++) {
        const regex = regexes[index];
        match = !!path.match(new RegExp(regex, "i"));
        if (match) {
          break;
        }
      }
      if (!match && PLUGIN_CONFIG.default_plugin_location) {
        match = !!getPluginLocation();
      }
    }
    return match;
  }

  function setCookiesOnSuccess(response) {
    for (const c of response) {
      const cookie = c.name + "=" + c.value + "; max-age=" + c.maxage + "; host=" + c.host + "; path=" + c.path + ";";
      document.cookie = cookie;
    }
  }

  /** Post analytics to firebase (via logEvent) and tp-hit analytics */
  function postAnalytics(action: string | null, params?: any) {
    analytics_queue.push(action);
    dequeueAnalytics();

    if (action && !action.startsWith("moreinfo_") && !action.startsWith("superlative_")) {
      logEvent(action, params);
    }
  }

  // Causes us to fire analytics events one at a time in sequence
  async function dequeueAnalytics() {
    if (analytics_queue.length === 0 || analytics_lock) {
      return;
    }

    const action = analytics_queue.shift();
    analytics_lock = fireAnalytics(action);
    await analytics_lock;
    analytics_lock = null;
    return dequeueAnalytics();
  }

  function fireAnalytics(action) {
    return new Promise<void>(async (resolve, reject) => {
      const plugin_location = getPluginLocation();
      let url = PLUGIN_AJAX_URL + "/tp-hit?dealer_id=" + DEALER_ID + "&plugin_id=" + PLUGIN_ID + "&location=" + plugin_location;
      if (action != null) {
        url += "&action=" + action;
      }
      url = tpUtil.tp_decorate(url);
      const parser = document.createElement("a");
      parser.href = window.location.href;
      const host_url = parser.protocol + "//" + parser.host + "/";
      url += "&host_url=" + encodeURIComponent(host_url);
      try {
        const response = await fetch(url);
        const data = await response.json();
        setCookiesOnSuccess(data);
      } catch (ex) {
        if (DEBUG_ENABLED) debugMessage("Error firing analytics event: ", ex);
      } finally {
        resolve();
      }
      if (DEBUG_ENABLED) debugMessage("Firing tp analytics event: ", action, plugin_location, DEALER_ID);
      if (PLUGIN_CONFIG.ddc_tracking && action === "typeahead" && typeof window._pxam === "object") {
        if (DEBUG_ENABLED) debugMessage("Firing DDC start tracking event");
        window._pxam.push({
          type: "event",
          eventType: "start",
          eventSource: "trade-in-offer",
          feature: "TradePending",
          label: plugin_location,
        });
      }
    });
  }

  function fire_snap_install_event() {
    postAnalytics(null);
    fire_shift_digital_event("tradeInImpression");
  }

  const SHIFT_OEMS = ["subaru", "toyota", "lexus", "fca", "gm"];
  function fire_shift_digital_event(event) {
    if (typeof window.sd === "function" && SHIFT_OEMS.includes(PLUGIN_CONFIG.program_oem)) {
      const data = {
        tradeInSessionId: tp_analytics_session_id,
        tradeInProvider: "tradepending",
        events: event,
      };
      if (DEBUG_ENABLED) debugMessage("TradePending: Fire shift tradeInImpression event", data);
      window.sd("dataLayer", data);
      window.sd("send");
    }
  }

  function fire_snapoffer_autostart_event() {
    postAnalytics("so_autostart_impression");
  }

  function fire_tradein_start_events(initiator: string) {
    fire_ga_event("initiated", initiator, "SNAP");
    fire_ga_event("asc_retail_process", initiator, "SNAP", "SNAP", { flow_name: "trade", flow_outcome: "start" }, { isAscEvent: true });
    if (typeof window.sd === "function" && SHIFT_OEMS.includes(PLUGIN_CONFIG.program_oem)) {
      const data = {
        tradeInSessionId: tp_analytics_session_id,
        tradeInProvider: "tradepending",
        events: "tradeInStart",
      };
      if (DEBUG_ENABLED) debugMessage("TradePending: Fire shift tradeInStart event", data);
      window.sd("dataLayer", data);
      window.sd("send");
    }
  }

  function fire_vehicle_selected_events(vehicle, product = "Trade") {
    fire_ga_event("selected", vehicle.ymmt, product);
    const vehicleParams = formatVehicle(vehicle);
    fire_ga_event(
      "asc_retail_process",
      vehicle.ymmt,
      product,
      product,
      {
        flow_name: "trade",
        flow_outcome: "vehicle selected",
        ...vehicleParams,
      },
      { isAscEvent: true }
    );
    logEvent("selected", { ...vehicle, product });

    if (typeof window.sd === "function" && SHIFT_OEMS.includes(PLUGIN_CONFIG.program_oem)) {
      const data = {
        tradeInSessionId: tp_analytics_session_id,
        tradeInProvider: "tradepending",
        tradeInYear: vehicle.year,
        tradeInMake: vehicle.make,
        tradeInModel: vehicle.model,
        events: "tradeInVehicleIndicated",
      };
      if (DEBUG_ENABLED) debugMessage("TradePending: Fire shift tradeInVehicleIndicated event", data);
      window.sd("dataLayer", data);
      window.sd("send");
    }
  }

  function fire_autoapr_start_events(
    config: {
      ga_category_name?: string;
      url_constructor?: (vin?: string) => string;
      vin?: string;
      cta_button_text?: string;
      analytics: {
        cta: string;
      };
    },
    event?: Event
  ) {
    const { ga_category_name, url_constructor, vin } = config;
    const event_action = "Button Click";
    const event_category = `${event_action} - AutoAPR ${ga_category_name}`;
    const event_label = vin ? url_constructor(vin) : url_constructor();
    let buttonText = config.cta_button_text;

    if (!buttonText && event?.target) {
      // This might be the buying power banner. Try to extract the text of the banner.
      const target = event.target as HTMLElement;
      const title = target.querySelector("h2");
      buttonText = title?.innerText.replace(/\n/g, " ");

      if (!buttonText) {
        // Maybe Explore My Payments floating button?
        buttonText = target.innerText;
      }
    }

    fire_ga_event(event_action, event_label, event_category);
    fire_asc_cta_event({ product: "Payments", vehicle: { vin }, elementText: buttonText, actionResult: "open" });
    logEvent("aa_cta_click", { cta: config.analytics?.cta });
  }

  function parse_query_params() {
    return tpUtil.parse_query_params(window.location.search);
  }

  function setupAccessibility() {
    const inputEl = document.querySelector<HTMLElement>("#trade-pending-widget");
    inputEl?.setAttribute("aria-live", "polite");
    const titleEl = document.querySelector("#tradepending-panel h1, #tradepending-panel h2, #tradepending-panel h3, #tradepending-panel .title");
    if (titleEl) {
      const text = titleEl.innerHTML;
      const input = document.querySelector<HTMLElement>("#tradepending-vehicle-typeahead");
      if (input) {
        if (text) {
          input.setAttribute("aria-label", text);
        } else {
          input.setAttribute("aria-label", "Value your trade");
        }
      }
    } else {
      const input = document.querySelector("#tradepending-vehicle-typeahead");
      input?.setAttribute("aria-label", "Value your trade");
    }
    const image = document.querySelector("#tp-poweredby, #tradepending-poweredby");
    image?.setAttribute("alt", "Trade Pending Logo");
    const imageContainer = document.querySelector(".tradepending-poweredby-container");
    imageContainer?.setAttribute("aria-label", "Trade Pending logo");
  }

  function preventInputHighjacks() {
    if (PLUGIN_CONFIG.website_vendor_name === "DealerInspire") {
      function handleEvent(event: KeyboardEvent | FocusEvent) {
        if (typeof window.$ae === "function") {
          // Deal with AudioEye highjacking the space key
          window.$ae("#tradepending-input input").on("keydown", function (e) {
            if (e.key === "Enter") {
              e.preventDefault();
              e.target.click();
            }
          });
        }
      }

      document.querySelectorAll("#tradepending-input input").forEach((input) => {
        input.addEventListener("focus", handleEvent);
        input.addEventListener("keydown", handleEvent);
      });
    }
  }

  function isBlockedStagingSite(product_status) {
    if (product_status === "staging" && PLUGIN_CONFIG.staging_url) {
      const parser = document.createElement("a");
      parser.href = PLUGIN_CONFIG.staging_url;
      if (parser.hostname === window.location.hostname && (parser.pathname.length <= 1 || window.location.pathname.startsWith(parser.pathname))) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function insert_default(plugin: SnapLocationData, widget_html: string, using_default_plugin_location: boolean, retries: number) {
    if (DEBUG_ENABLED) debugMessage("insert_default for attempt", retries);
    const plugin_location = getPluginLocation();
    let widget_css_selector = "#tradepending-plugin-container";
    if (is_mobile && typeof plugin.mobile_css_selector !== "undefined") widget_css_selector = plugin.mobile_css_selector;
    else if (typeof plugin.css_selector !== "undefined") widget_css_selector = plugin.css_selector;

    const widget_element = domUtils.jqSelect<HTMLElement>(widget_css_selector);
    let widget_insert_method: InsertMethod = "append";
    if (is_mobile && typeof plugin.mobile_insert_method !== "undefined") widget_insert_method = plugin.mobile_insert_method;
    else if (typeof plugin.insert_method !== "undefined") widget_insert_method = plugin.insert_method;

    if (DEBUG_ENABLED) debugMessage("Using plugin: " + plugin_location + " and insert method: " + widget_insert_method + " on element: ", widget_element[0]);
    if (using_default_plugin_location && widget_element.length > 0) {
      if (DEBUG_ENABLED) debugMessage("Inserting using default plugin location");
    }
    if (widget_element.length > 0) {
      if (is_mobile && tpUtil.isLandingOrInline()) {
        widget_html = buildVehicleSelectFrame({ is_mobile, plugin });
      } else if (widget_html.includes("<iframe")) {
        widget_html = tpUtil.add_utm_params_to_iframe(widget_html, false);
      }
      if (PLUGIN_CONFIG.reinstall_location_change) {
        domUtils.remove("#tradepending-container");
      }

      apply_insert_method(widget_element, widget_insert_method, widget_html);
      snap_widget_installed = true;
      fire_snap_install_event();

      if (widget_element.length > 1 && !plugin.allow_multiple) {
        reportError("selector", { type: "select_multiple", location: plugin_location });
      }
      if (is_mobile && tpUtil.isLandingOrInline()) {
        const iframe = document.getElementById("tradepending-inline-iframe");
        if (iframe) {
          iframe.onload = function () {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          };
        }
      }
    } else if (!using_default_plugin_location) {
      if (DEBUG_ENABLED) debugMessage("TradePending: TradePending SNAP selector not found: " + widget_css_selector);
      if (retries < 3) {
        if (DEBUG_ENABLED) debugMessage("Retrying install, after attempt", retries);
        setTimeout(() => {
          insert_default(plugin, widget_html, using_default_plugin_location, retries + 1);
        }, retries * 1500);
      }
    }
  }

  async function installSnapDdcWidget(plugin: SnapLocationData, widget_html: string) {
    if (!HAS_DDC) return;
    return new Promise<void>(function (resolve, reject) {
      try {
        const css_selector = tpUtil.getCssSelector(plugin);
        DDC_API.subscribe("page-load-v1", (ev) => {
          DDC_API.insert(css_selector, (elem, meta) => {
            try {
              if (snap_widget_installed) {
                if (DEBUG_ENABLED) debugMessage("TradePending: skipping second call to installSnapDdcWidget since the widget is already installed");
              } else {
                snap_widget_installed = true;
                widget_html = "<div>" + widget_html + "</div>";
                if (DEBUG_ENABLED) debugMessage("inserting SNAP via ddc api: " + css_selector);
                DDC_API.append(elem, domUtils.toElement(widget_html));
              }
            } catch (err) {
              console.log("error calling DDC.append", err);
            }
            fire_snap_install_event();
            resolve();
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  async function installSnapDdcLandingPage(plugin: SnapLocationData, widget_html: string) {
    if (!HAS_DDC) return;
    return new Promise<void>(function (resolve, reject) {
      try {
        DDC_API.subscribe("page-load-v1", (ev) => {
          if (ddcUtils.isDdcPageNameMatch(ev.payload.pageName, ["snapOffer", "snap"])) {
            DDC_API.insert("content", (elem, meta) => {
              try {
                if (snap_widget_installed) {
                  if (DEBUG_ENABLED) debugMessage("TradePending: skipping second call to installSnapDdcLandingPage since the widget is already installed");
                } else {
                  snap_widget_installed = true;
                  if (is_mobile && tpUtil.isLandingOrInline()) {
                    widget_html = buildVehicleSelectFrame({ is_mobile, plugin });
                  } else {
                    widget_html = "<div>" + widget_html + "</div>";
                  }
                  if (DEBUG_ENABLED) debugMessage(`inserting SNAP via ddc api on landing page: ${ev.payload.pageName}`);
                  DDC_API.append(elem, domUtils.toElement(widget_html));
                }
              } catch (err) {
                console.log("error calling DDC.append", err);
              }
              fire_snap_install_event();
              resolve();
            });
          } else {
            if (DEBUG_ENABLED) debugMessage(`installSnapDdcLandingPage: pageName ${ev.payload.pageName} did not match expected page names.`);
            resolve();
          }
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  function enableDisplay() {
    if (is_mobile) {
      document.addEventListener("cboxTP_open", () => {
        document.querySelector("html").style.overflow = "hidden";
      });
      document.addEventListener("cboxTP_closed", () => {
        document.querySelector("html").style.overflow = "";
      });
      domUtils.show("#tradepending-select");
      domUtils.show(".tradepending-mobile");
      domUtils.hide("#tradepending-input");
      domUtils.hide(".tradepending-hide-mobile");
    } else {
      domUtils.hide("#tradepending-select");
      domUtils.hide(".tradepending-mobile");
      domUtils.show("#tradepending-input");
      domUtils.show(".tradepending-hide-mobile");
    }
  }

  function installReloadListeners(plugin_config: PluginConfig) {
    if (DEBUG_ENABLED) debugMessage("SNAP setup SRP reload listeners");
    vrp_ready_listener_installed = true;
    if (plugin_config.website_vendor_name === "DealerInspire") {
      // jquery needed for custom events
      if (typeof jQuery !== "undefined") {
        jQuery("body").on("vrp-ready vrp-ajax-complete", () => {
          if (DEBUG_ENABLED) debugMessage("DealerInspire SRP reload");
          install();
        });
      }
    }
    if (plugin_config.website_vendor_name === "Sokal") {
      // jQuery needed for these custom events
      if (typeof jQuery !== "undefined") {
        jQuery(document).on("infiniteScrollFinished ws-search-results", (event) => {
          if (DEBUG_ENABLED) debugMessage("Sokal SRP reload " + event.type);
          domUtils.remove("div#tradepending-container");
          install();
        });
      }
    }
  }

  function shouldInstallProduct(product_config, product_location_config, options?) {
    const active_statuses = ["live", "needs_qa", "partial", "canceled", "staging", "working"];
    let product;
    if (options?.is_aa) {
      product = "AutoAPR";
    } else if (options?.is_value_tracker) {
      product = "Value Tracker";
    } else {
      product = "CTAs";
    }
    if (product_config?.locations?.[getPluginLocation()]) {
      if (!product_location_config || product_location_config.disabled || !active_statuses.includes(product_config.status) || !product_location_config?.templates) {
        if (document.cookie.match(/tp_test_product=true/) == null) {
          return false;
        }
      }
      if (isBlockedStagingSite(product_config.status)) {
        if (DEBUG_ENABLED) debugMessage(`${product} not displaying because it's a staging site that doesn't match the configured staging url: ${PLUGIN_CONFIG.staging_url}`);
        return false;
      }
      try {
        if (options?.is_aa && !product_config.dealer_id) {
          console.error("TradePending: Error: Missing Auto APR dealer code");
          return false;
        } else if (options?.is_value_tracker && !product_location_config.templates) {
          console.error("TradePending: Error: Missing Value Tracker templates");
          return false;
        }
        return true;
      } catch (err) {
        console.log(`error installing ${product} widgets `, err);
      }
    }
    return false;
  }

  let debouncedInstallCtas, debouncedInstallAutoApr;
  const installAutoApr = async () => {
    const aa_location_config = getAutoAprLocationConfig();
    if (shouldInstallProduct(AUTOAPR_CONFIG, aa_location_config, { is_aa: true })) {
      try {
        if (aa_location_config.custom_css) {
          domUtils.addCssToHead(`aa-location-${getPluginLocation()}`, aa_location_config.custom_css);
        }
        installBannerOrLandingPage(aa_location_config, "autoapr");
        installAutoAprFloatingButton(aa_location_config);
      } catch (err) {
        console.log("error installing AutoAPR widgets ", err);
      }
    }
  };

  function getValueTrackerLocationConfig() {
    if (VALUE_TRACKER_CONFIG?.locations) {
      return VALUE_TRACKER_CONFIG?.locations[getPluginLocation()];
    }
  }

  const installValueTracker = async () => {
    const value_tracker_config = getValueTrackerLocationConfig();
    if (shouldInstallProduct(VALUE_TRACKER_CONFIG, value_tracker_config, { is_value_tracker: true })) {
      try {
        if (value_tracker_config.custom_css) {
          domUtils.addCssToHead(`value-tracker-location-${getPluginLocation()}`, value_tracker_config.custom_css);
        }
        const has_vt_banner_template = Object.keys(value_tracker_config.templates).filter((key) => /banner/.exec(key));
        if (value_tracker_config.templates && has_vt_banner_template) installBannerOrLandingPage(value_tracker_config, "value_tracker");
        const vt_typeahead = installTypeahead("#value_tracker_typeahead");
        vt_typeahead.once("query").then(() => {
          postAnalytics("typeahead");
        });

        vt_typeahead.on("select", (vehicle) => {
          if (DEBUG_ENABLED) debugMessage("TradePending: vt_typeahead");
          let initiator = "button";
          fire_tradein_start_events(initiator);
          fire_vehicle_selected_events(vehicle);
          launchContactForm(vehicle, { form: undefined, height: undefined, top: undefined, fixed: undefined, is_value_tracker: true });
        });

        if (isMobile()) {
          installButtonListeners(Array.from(document.querySelectorAll(".tp-btn-value-tracker-vehicle-select")), mobileButtonListenerFunc(value_tracker_config, { is_value_tracker: true }));
        }
      } catch (err) {
        console.log("error installing Value Tracker widgets ", err);
      }
    }
  };

  const build_wrapper = (uniqueClass: string, widgetHtml: string) => {
    const wrapper = document.createElement("div");
    wrapper.className = `${uniqueClass}`;
    wrapper.innerHTML = widgetHtml;
    return wrapper as HTMLElement;
  };

  async function shouldShowDdcCta(locationConfig: CtaLocationData | AutoAprLocationData) {
    if (!HAS_DDC) return false;
    if (!DDC_API) return false;
    const dealerDDCInfo = await DDC_API.utils.getDealerData();
    const vehicleDDCInfo = await DDC_API.utils.getVehicleData();
    const dealerRegex = locationConfig?.listing_codes_dealer_regex;
    let displayDealerCTA = false;
    if (!!dealerRegex) {
      let displayDealerCtaVdp = getPluginLocation() === "vdp";
      if (locationConfig.remove_ctas_for_listing_codes) {
        displayDealerCtaVdp = displayDealerCtaVdp && vehicleDDCInfo.every((vehicleInfo) => !vehicleInfo.accountId.match(new RegExp(dealerRegex)));
      } else {
        displayDealerCtaVdp = displayDealerCtaVdp && vehicleDDCInfo.some((vehicleInfo) => vehicleInfo.accountId.match(new RegExp(dealerRegex)));
      }
      displayDealerCTA = displayDealerCtaVdp || getPluginLocation()?.startsWith("srp");
    }
    const displayCTA = ![AUTONATION_DEALERSHIP_NAME, TOM_WOOD_DEALERSHIP_NAME].includes(dealerDDCInfo?.dealershipName) || displayDealerCTA;
    if (!displayCTA) {
      if (DEBUG_ENABLED) debugMessage(`Not inserting ${dealerDDCInfo?.dealershipName} VDP CTA`);
    }
    return displayCTA;
  }

  async function installCtas() {
    if (DEBUG_ENABLED) debugMessage("install installCtas()");
    let template_keys: string[] = [];
    const plugin_location = getPluginLocation();
    const snapPlugin = PLUGIN_DATA[plugin_location];
    const shouldInstallTradeDDCCallToAction = DDC_API && snapPlugin?.use_ddc_api && snapPlugin?.use_ddc_api_cta;
    const shouldInstallTradeCallToAction = shouldInstallWidgetOnDevice(snapPlugin?.cta_disable_mobile, snapPlugin?.cta_disable_desktop) && (snapPlugin?.cta_css_selector || shouldInstallTradeDDCCallToAction);
    if (shouldInstallTradeCallToAction) {
      template_keys.push("trade_cta");
    }
    const aa_location_config = getAutoAprLocationConfig();
    if (shouldInstallProduct(AUTOAPR_CONFIG, aa_location_config)) {
      template_keys = template_keys.concat(Object.keys(AA_PRODUCT_MAP).filter((key) => /cta/.exec(key) && aa_location_config.templates[key]));
    }
    const ctas_location_config = CTAS_CONFIG?.locations[plugin_location];
    if (shouldInstallProduct(CTAS_CONFIG, ctas_location_config)) {
      template_keys = template_keys.concat(Object.keys(CTAS_MAP).filter((key) => ctas_location_config.templates[key]));
      if (ctas_location_config?.custom_css) {
        domUtils.addCssToHead(`ctas-location-${getPluginLocation()}`, ctas_location_config.custom_css);
      }
    }
    const value_tracker_location_config = getValueTrackerLocationConfig();
    if (shouldInstallProduct(VALUE_TRACKER_CONFIG, value_tracker_location_config, { is_value_tracker: true })) {
      template_keys = template_keys.concat(Object.keys(VALUE_TRACKER_MAP).filter((key) => /cta/.exec(key) && value_tracker_location_config.templates[key]));
      if (value_tracker_location_config?.custom_css) {
        domUtils.addCssToHead(`value-tracker-location-${getPluginLocation()}`, value_tracker_location_config.custom_css);
      }
    }
    const location_config = PLUGIN_LOCATION_CONFIGS[getPluginLocation()];
    if (location_config?.ctas_order) {
      template_keys.sort((a, b) => (a === "combined_cta" || b === "combined_cta" ? 0 : location_config.ctas_order.indexOf(a) - location_config.ctas_order.indexOf(b)));
    }
    for (const template_key of template_keys) {
      if (template_key === "trade_cta") {
        if (!shouldInstallTradeDDCCallToAction && is_mobile) {
          snapPlugin.cta_css_selector = snapPlugin.cta_mobile_css_selector || snapPlugin.cta_css_selector;
          snapPlugin.cta_insert_method = snapPlugin.cta_mobile_insert_method || snapPlugin.cta_insert_method;
        }
        await installCallToAction({ ...snapPlugin, cta_unique_class: "snap-cta" }, TP_CTA_SELECTOR, shouldInstallTradeDDCCallToAction, installCallToActionDefault);
      } else {
        const isAutoApr = AA_PRODUCT_MAP.hasOwnProperty(template_key);
        const isValueTracker = template_key.includes("value_tracker");
        let product_config;
        if (isAutoApr) product_config = aa_location_config;
        else if (isValueTracker) product_config = value_tracker_location_config;
        else product_config = ctas_location_config;
        const template_config = product_config.templates[template_key];
        if (template_config) {
          const cta_unique_class = `${template_key}_button`;
          let productMap;
          if (isAutoApr) productMap = AA_PRODUCT_MAP;
          else if (isValueTracker) productMap = VALUE_TRACKER_MAP;
          else productMap = CTAS_MAP;
          if (shouldInstallBannerOrCta(template_config)) {
            if (template_config?.widget_css) {
              domUtils.addCssToHead(template_config.standardized_cta ? "standardized_cta" : template_key, template_config.widget_css);
            }
            if (is_mobile) {
              template_config.node_selector = template_config.mobile_node_selector || template_config.node_selector;
              template_config.selector = template_config.mobile_selector || template_config.selector;
              template_config.insert_method = template_config.mobile_insert_method || template_config.insert_method;
              template_config.vin_selector = template_config.mobile_vin_selector || template_config.vin_selector;
              template_config.stock_number_selector = template_config.mobile_stock_number_selector || template_config.stock_number_selector;
              template_config.price_selector = template_config.mobile_price_selector || template_config.price_selector;
            }
            const variables = template_config?.variables || {};
            const plugin: CtaPlugin = {
              cta_button_type: template_config.cta_button_type || "",
              cta_insert_selector: template_config.selector,
              cta_insert_method: template_config.insert_method,
              cta_vin_selector: template_config.vin_selector,
              cta_stock_number_selector: template_config.stock_number_selector,
              cta_price_selector: template_config.price_selector,
              cta_html_code: template_config.widget_html,
              cta_button_border_style: variables?.border_style || variables["cta-button-widget-border-style"] || "",
              cta_button_border_radius: variables?.border_radius || variables["cta-button-widget-border-radius"] || "",
              cta_button_text_color: (variables && (variables.text_color || variables["text-color"] || variables["cta-button-widget-text-color"])) || "",
              cta_button_color: (variables && (variables.background_color || variables["button-color"] || variables["cta-button-widget-background-color"])) || "",
              cta_button_class: template_config.cta_button_class || variables?.button_classes || variables["cta-button-widget-classes"] || "",
              cta_intent: productMap[template_key].cta_intent || "payment-calculator",
              cta_button_text: variables?.title || variables["cta-button-widget-title"] || variables?.inner_html || productMap[template_key].cta_title || "Calculate Your Payment",
              is_autoapr: isAutoApr,
              use_condition_check: productMap[template_key].use_condition_check,
              cta_unique_class,
              ga_category_name: productMap[template_key].product_name,
              url_constructor: (vin: string) => {
                if (isAutoApr) {
                  return `${productMap[template_key].url}${AUTOAPR_CONFIG.dealer_id}/${vin}`;
                } else if (template_key === "value_tracker_cta") {
                  const q_params = "?is_value_tracker=true";
                  return `${window.location.protocol}//${PLUGIN_SERVER}/snap/iframe/${PLUGIN_ID}${q_params}`;
                }
                return `${window.location.protocol}//${PLUGIN_SERVER}/contact/form?type=${productMap[template_key].type}&did=${PLUGIN_ID}&loc=${plugin_location}&vin=${vin}`;
              },
              ctas_enabled: undefined as undefined | Set<string>,
              price_selector_disabled_new: template_config.disable_price_selector_on_new,
              price_selector_disabled_used: template_config.disable_price_selector_on_used,
              use_stock_numbers: product_config.use_stock_numbers,
              untie_stock_number_from_vin: product_config.untie_stock_number_from_vin,
              stock_numbers_pattern: product_config.stock_numbers_pattern,
              use_custom_vin_pattern: product_config.use_custom_vin_pattern,
              custom_vin_pattern: product_config.custom_vin_pattern,
              listing_codes_dealer_regex: product_config.listing_codes_dealer_regex,
              remove_ctas_for_listing_codes: product_config.remove_ctas_for_listing_codes,
              intermediate_url_constructor: undefined,
              template_key,
              use_ddc_api_append: product_config.use_ddc_api_append,
              ddc_price_override: template_config.ddc_price_override,
              ddc_year_condition_override: template_config.ddc_year_condition_override,
              analytics: { cta: template_key },
            };
            addEventContext(`${isAutoApr ? "aa" : "additional"}_cta_click`, template_key, true);
            if (template_key === "combined_cta") {
              if (!template_config.ctas) {
                template_config.ctas = [];
              }
              plugin.ctas_enabled = new Set<string>(template_config.ctas.map((name) => `${name}_cta`));
              plugin.ctas_enabled.add("reveal_cta");
            }

            const displayCTA = await shouldShowDdcCta((isAutoApr ? AUTOAPR_CONFIG : CTAS_CONFIG)?.locations?.vdp);
            const useDdcApi = DDC_API && product_config.use_ddc_api;
            if (useDdcApi && product_config.use_ddc_api_append && displayCTA) {
              await installDDCCallToAction(plugin);
            } else {
              if (template_key === "combined_cta") {
                await installCallToAction(plugin, template_config.node_selector, useDdcApi, installAutoAprCombinedCta);
              } else {
                if (!useDdcApi || (useDdcApi && displayCTA)) {
                  await installCallToAction(plugin, template_config.node_selector, useDdcApi, installCallToActionDefault);
                }
              }
            }

            const postImpressions = (type) => {
              switch (type) {
                case "test_drive_cta":
                  postAnalytics("aa_drv_view");
                  break;
                case "approve_cta":
                  postAnalytics("aa_apv_view");
                  break;
                case "lease_cta":
                  postAnalytics("aa_lse_view");
                  break;
                case "reveal_cta":
                  postAnalytics("aa_rvl_view");
                  break;
                case "eprice_cta":
                  postAnalytics("epr_view");
                  break;
                case "availability_cta":
                  postAnalytics("avl_view");
                  break;
                case "test_drive_v2_cta":
                  postAnalytics("drv_view");
                  break;
                default:
                  if (DEBUG_ENABLED) debugMessage(`TradePending: not posting analytics for unknown template_key: ${type}`);
                  break;
              }
            };

            if (template_key === "combined_cta" && plugin.ctas_enabled) {
              for (const cta of plugin.ctas_enabled.values()) {
                postImpressions(cta);
              }
            } else {
              postImpressions(template_key);
            }
          }
        }
      }
    }
  }

  const banners_installed = {};
  const installBannerDDC = (css_selector: string, insertion_element, templateKey: string, install_listeners_func, product?: string) => {
    if (!HAS_DDC) return;
    return new Promise<void>(function (resolve, reject) {
      try {
        DDC_API.insert(css_selector, (elem, meta) => {
          try {
            if (banners_installed[templateKey]) {
              if (DEBUG_ENABLED) debugMessage(`TradePending: skipping second call to installBannerDDC for ${product} since the widget: ${templateKey} is already installed`);
            } else {
              banners_installed[templateKey] = true;
              if (DEBUG_ENABLED) debugMessage(`inserting ${product} Banner via ddc api at ${css_selector} for ${templateKey}`);
              DDC_API.append(elem, insertion_element);
              install_listeners_func();
            }
          } catch (err) {
            console.log("error calling DDC.append", err);
          }
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  let landingPageInstalled = false;
  const installDdcLandingPage = async (css_selector: string, insertion_element, templateKey: string, install_listeners_func, product?: string) => {
    if (!HAS_DDC) return;
    return new Promise<void>(function (resolve, reject) {
      try {
        const page_names = product === "AutoAPR" ? ["reveal", "approve"] : ["value-tracker"];
        DDC_API.subscribe("page-load-v1", (ev) => {
          if (ddcUtils.isDdcPageNameMatch(ev.payload.pageName, page_names)) {
            DDC_API.insert("content", (elem, meta) => {
              try {
                if (landingPageInstalled) {
                  if (DEBUG_ENABLED) debugMessage("TradePending: skipping second call to installLandingPageDDC since the widget is already installed");
                } else {
                  landingPageInstalled = true;
                  if (DEBUG_ENABLED) debugMessage(`inserting ${product} Landing Page via ddc api at ${css_selector} for ${templateKey}`);
                  DDC_API.append(elem, insertion_element);
                  install_listeners_func();
                }
              } catch (err) {
                console.log("error calling DDC.append", err);
              }
            });
          } else if (DEBUG_ENABLED) {
            debugMessage(`installDdcLandingPage: pageName ${ev.payload.pageName} did not match expected page names.`);
          }
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  };
  function getAutoAprLocationConfig() {
    if (AUTOAPR_CONFIG?.locations) {
      return AUTOAPR_CONFIG?.locations[getPluginLocation()];
    }
    return null;
  }

  function installBannerOrLandingPage(plugin_config: AutoAprLocationData | ValueTrackerLocationData, product?: string) {
    const product_map = product === "value_tracker" ? VALUE_TRACKER_MAP : AA_PRODUCT_MAP;
    const product_name = product === "value_tracker" ? "Value Tracker" : "AutoAPR";
    const is_autoapr = product === "autoapr";
    const is_value_tracker = product === "value_tracker";
    const templateKeys = Object.keys(product_map).filter((key) => /banner/.exec(key) || /landing_page/.exec(key));
    for (const templateKey of templateKeys) {
      if (DEBUG_ENABLED) debugMessage(`install ${product_name}: `, templateKey);
      const uniqueClass = templateKey;
      const templateConfig = plugin_config.templates[templateKey];
      if (shouldInstallBannerOrCta(templateConfig)) {
        if (templateConfig?.widget_css) {
          domUtils.addCssToHead(templateKey, templateConfig.widget_css);
        }

        const selector = is_mobile ? templateConfig.mobile_selector || templateConfig.selector : templateConfig.selector;
        const root = domUtils.jqSelect(selector);
        const existingElements = root.length ? root[0].parentElement.querySelectorAll(`.${uniqueClass}`) : [];
        if (existingElements.length >= 1) {
          // short circuit if element already exists
          break;
        }
        let widget_insert_method: InsertMethod = "append";

        if (is_mobile && typeof templateConfig.mobile_insert_method !== "undefined") widget_insert_method = templateConfig.mobile_insert_method;
        else if (typeof templateConfig.insert_method !== "undefined") widget_insert_method = templateConfig.insert_method;

        const el = build_wrapper(uniqueClass, templateConfig.widget_html);
        const form = el.querySelector("form");
        const config = {
          is_autoapr,
          is_value_tracker,
          ga_category_name: undefined,
          url_constructor: undefined,
          analytics: {
            cta: templateKey,
          },
        };
        if (is_autoapr) {
          // track which banners are enabled
          addEventContext("aa_cta_click", `${templateKey}`, true);
        }

        const addFormListener = form?.length > 0;
        const addListeners = () => {
          if (templateKey === "combined_banner") {
            addPaymentExplorerEventListener(templateKey, { ...config });
            addApproveEventListener(templateKey, { ...config });
          } else if (addFormListener) {
            addPaymentExplorerEventListener(templateKey, config);
          } else {
            addApproveEventListener(templateKey, config);
          }
        };
        if (DDC_API && plugin_config.use_ddc_api) {
          if (templateKey.match(/banner/)) {
            installBannerDDC(selector, el, templateKey, addListeners, product_name);
          } else if (templateKey.match(/landing/) && ddcUtils.isDdcLandingPage()) {
            installDdcLandingPage(selector, el, templateKey, addListeners, product_name);
          }
        } else {
          const banner_location = domUtils.jqSelect<HTMLElement>(selector);
          if (DEBUG_ENABLED) debugMessage("Using plugin: " + getPluginLocation() + " and insert method: " + widget_insert_method + " on element: ", banner_location[0]);

          if (!banner_location.length && !(DDC_API && plugin_config.use_ddc_api)) {
            console.error(`Unable to find selector ${selector} for inserting ${templateKey}`);
          }

          const widget_el = el;
          let widget_html = widget_el.outerHTML;
          if (widget_html.includes("<iframe")) {
            widget_html = tpUtil.add_utm_params_to_iframe(widget_html, true);
          }

          apply_insert_method(banner_location, widget_insert_method, widget_html);
          if (is_autoapr) {
            addListeners();
          }
        }

        if (is_autoapr) {
          postAnalytics("aa_exp_view_banner");
        }
      }
    }
  }

  function addPaymentExplorerEventListener(
    templateKey: string,
    config: {
      ga_category_name?: string;
      url_constructor?: (vin?: string) => string;
      analytics: {
        cta: string;
      };
    }
  ) {
    const mountedForm = document.querySelectorAll(".aa-exp-card form");
    if (mountedForm?.length > 0) {
      for (const bannerForm of Array.from(mountedForm)) {
        bannerForm.addEventListener("submit", (ev) => {
          ev.preventDefault();
          const formInput = bannerForm.querySelector("input");

          let url = `${templateKey === "combined_banner" ? AA_PRODUCT_MAP[templateKey].url("explorer") : AA_PRODUCT_MAP[templateKey].url}${AUTOAPR_CONFIG.dealer_id}/`;
          if (formInput && formInput.value.length > 0) {
            // add budget to URL
            url += `${formInput.value}`;
          }
          config.ga_category_name = AA_PRODUCT_MAP[templateKey].product_name;
          config.url_constructor = () => url;
          fire_autoapr_start_events(config, ev);
          launchOverlay(config, launchColorboxFullProcessPopup);
        });
      }
    }
  }

  function addApproveEventListener(
    templateKey: string,
    config: {
      ga_category_name?: string;
      url_constructor?: () => string;
      analytics: {
        cta: string;
      };
    }
  ) {
    config.ga_category_name = AA_PRODUCT_MAP[templateKey].product_name;
    config.url_constructor = () => `${templateKey === "combined_banner" ? AA_PRODUCT_MAP[templateKey].url("approve") : AA_PRODUCT_MAP[templateKey].url}${AUTOAPR_CONFIG.dealer_id}/`;
    const button = document.querySelector<HTMLButtonElement>(".aa-apv-btn");
    button?.addEventListener("click", (evt) => {
      evt.preventDefault();
      fire_autoapr_start_events(config, evt);
      logEvent("aa_banner_click", { banner: templateKey });
      launchOverlay(config, launchColorboxFullProcessPopup);
    });
  }

  function shouldInstallBannerOrCta(templateConfig: SnapLocationData | CtaTemplate) {
    const pluginLocationConfig = PLUGIN_LOCATION_CONFIGS[getPluginLocation()];
    if (!templateConfig) {
      return false;
    }
    if (!shouldInstallWidgetOnDevice(templateConfig?.disable_mobile, templateConfig?.disable_desktop)) {
      return false;
    }

    let locationPath = getPath();
    if (PLUGIN_CONFIG.apply_to_path_and_query) {
      locationPath = locationPath + window.location.search;
    }
    if (
      (templateConfig?.disable_on_new && pluginLocationConfig?.new_url_regex && locationPath.match(pluginLocationConfig?.new_url_regex)) ||
      (templateConfig?.disable_on_used && pluginLocationConfig?.used_url_regex && locationPath.match(pluginLocationConfig?.used_url_regex))
    ) {
      return false;
    }
    return true;
  }

  async function installDDCCallToAction(plugin: CtaPlugin | (SnapLocationData & { cta_unique_class: string; vin?: string })) {
    if (!HAS_DDC) return;
    const launchDDCOverlay = (vin: string) => () => {
      plugin.vin = vin;
      fireCtaEvent(null, plugin, "cta_button");
      launchOverlay(plugin, () => {
        launchColorboxFullProcessPopup(plugin);
      });
    };
    DDC_API.subscribe("vehicle-data-updated-v1", (ev) => {
      const is_lease = plugin.cta_unique_class === "lease_cta_button";
      if ("use_ddc_api_append" in plugin && plugin.use_ddc_api_append) {
        ev.payload.vehicleData.forEach((vehicle) => {
          const insert_location = "vehicle-ctas";
          DDC_API.insertOnce(insert_location, (elem, meta) => {
            try {
              const isSnapCta = plugin.cta_unique_class === "snap-cta";
              let wrapper = build_wrapper(plugin.cta_unique_class, isSnapCta ? getSnapCTATemplate(plugin, document.querySelector(TP_CTA_SELECTOR)) : plugin.cta_html_code);
              if (plugin.template_key === "combined_cta") {
                wrapper = filterAutoAprCombinedCtaActions(plugin, wrapper);
              }

              if (!is_lease || meta.inventoryType === "new") {
                const metaVin = meta.vin;
                wrapper.setAttribute("data-vin", metaVin);
                if (DEBUG_ENABLED) debugMessage("inserting CTA via ddc api: " + insert_location);
                DDC_API.append(elem, wrapper);

                if (isSnapCta) {
                  installButtonListeners(wrapper, launchDDCOverlay(metaVin));
                } else {
                  let priceParam = "";
                  if (plugin.ddc_price_override && plugin.ddc_price_override != "None") {
                    const price = meta[plugin.ddc_price_override];
                    if (price) {
                      priceParam = `/${price}`;
                    }
                  }
                  let condYearParam = "";
                  if (plugin.ddc_year_condition_override && plugin.ddc_year_condition_override === true) {
                    const condition = meta.inventoryType === "new" ? "n" : "u";
                    const year = meta.year;
                    condYearParam = `?cd=${condition}&yr=${year}`;
                  }
                  if (plugin.template_key === "combined_cta") {
                    plugin.intermediate_url_constructor = (cta_key) => `${AA_PRODUCT_MAP[cta_key].url}${AUTOAPR_CONFIG.dealer_id}/${metaVin}${priceParam}${condYearParam}`;
                    installAutoAprCombinedCtaListeners({ ...plugin });
                  } else {
                    const isAutoApr = AA_PRODUCT_MAP.hasOwnProperty(plugin.template_key);
                    plugin.url_constructor = () => {
                      if (isAutoApr) {
                        return `${AA_PRODUCT_MAP[plugin.template_key].url}${AUTOAPR_CONFIG.dealer_id}/${metaVin}${priceParam}${condYearParam}`;
                      }
                      const plugin_location = getPluginLocation();
                      return `${window.location.protocol}//${PLUGIN_SERVER}/contact/form?type=${CTAS_MAP[plugin.template_key].type}&did=${PLUGIN_ID}&loc=${plugin_location}&vin=${metaVin}`;
                    };
                    if (isMobile()) {
                      installButtonListeners(wrapper, mobileButtonListenerFunc({ ...plugin }));
                    } else {
                      installButtonListeners(wrapper, desktopButtonListenerFunc({ ...plugin }));
                    }
                  }
                }
              }
            } catch (err) {
              console.log("error calling DDC.append", err);
            }
          });
        });
      } else {
        const cta_intent = "cta_intent" in plugin ? plugin.cta_intent : undefined;
        DDC_API.insertCallToActionOnce("button", cta_intent || "value-a-trade", (meta) => {
          if (!is_lease || meta.inventoryType === "new") {
            const getStyle = (style_name, value) => {
              return (tpUtil.exists(value) && `${style_name}: ${value};`) || "";
            };

            let style = getStyle("border", plugin.cta_button_border_style);
            style += getStyle("border-radius", plugin.cta_button_border_radius);
            style += getStyle("color", plugin.cta_button_text_color);
            style += getStyle("background", plugin.cta_button_color);

            const pluginResponse: DdcCtaPluginConfig = {
              type: plugin.cta_button_type || "default",
              target: "_blank",
              onclick: launchDDCOverlay(meta.vin),
              text: {
                en_US: plugin.cta_button_text || "Value Your Trade",
                en_CA: plugin.cta_button_text || "Value Your Trade",
                fr_CA: plugin.cta_button_text || "Obtenez la valeur de votre échange",
                es_MX: plugin.cta_button_text || "Conozca el Valor de su Auto",
              },
              style,
              classes: plugin.cta_button_class,
            };

            const ctaHtmlElement = domUtils.toElement(plugin.cta_html_code);
            const autoaprImageInfo = plugin.cta_html_code ? ctaHtmlElement.querySelectorAll(".aa-svg") : [];
            if (autoaprImageInfo?.length > 0) {
              let classes = ctaHtmlElement.className;
              Array.from(ctaHtmlElement.children).forEach((child) => {
                classes += child.className;
              });
              pluginResponse.classes = classes;
              pluginResponse.imgClasses = autoaprImageInfo[0].getAttribute("class");
              pluginResponse.imgSrc = autoaprImageInfo[0].getAttribute("src");
              pluginResponse.imgAlt = autoaprImageInfo[0].getAttribute("alt");
            }
            return pluginResponse;
          }
        });
      }
    });
  }

  async function installAutoAprCombinedCta(plugin: CtaPlugin, node: HTMLElement) {
    plugin.cta_html_code = build_wrapper(plugin.cta_unique_class, plugin.cta_html_code).outerHTML;
    const cta_elements = domUtils.jqSelect<HTMLElement>(plugin.cta_insert_selector, node);
    const cta_element = cta_elements.length ? cta_elements[0] : null;
    if (cta_element) {
      plugin.button_selector = `.${plugin.cta_unique_class}`;
      const toRemove = domUtils.jqSelect(plugin.button_selector, cta_element.parentElement);
      toRemove.forEach((el) => el.remove());
      plugin.cta_html_code = filterAutoAprCombinedCtaActions(plugin, domUtils.toElement(plugin.cta_html_code)).outerHTML;
      apply_insert_method(cta_element, plugin.cta_insert_method, plugin.cta_html_code);
      installAutoAprCombinedCtaListeners(plugin);
    } else {
      console.error("TradePending: TradePending CTA selector not found: " + plugin.cta_insert_selector);
    }
  }

  function filterAutoAprCombinedCtaActions(plugin: CtaPlugin, element: HTMLElement) {
    for (const cta of AA_PRODUCT_MAP.combined_cta.ctas) {
      const condition_check = AA_PRODUCT_MAP[cta].use_condition_check;
      if (!plugin.ctas_enabled.has(cta) || (condition_check && !condition_check(plugin.vehicle_condition?.condition))) {
        domUtils.remove(`.aa-combined-cta-${cta}`, element);
      }
    }
    return element;
  }

  function installAutoAprCombinedCtaListeners(plugin: CtaPlugin) {
    for (const cta of AA_PRODUCT_MAP.combined_cta.ctas) {
      if (plugin.ctas_enabled.has(cta)) {
        plugin.button_selector = `.aa-combined-cta-${cta}`;
        if (plugin.intermediate_url_constructor) {
          plugin.url_constructor = () => plugin.intermediate_url_constructor(cta);
        } else {
          plugin.url_constructor = (vin) => `${AA_PRODUCT_MAP[cta].url}${AUTOAPR_CONFIG.dealer_id}/${vin}`;
        }
        addEventContext("aa_cta_click", `${plugin.analytics.cta}_${cta}_enabled`, true);
        installDesktopButtonListeners({ ...plugin, analytics: { cta: `${plugin.analytics.cta}_${cta}` } });
        installMobileButtonListeners({ ...plugin, analytics: { cta: `${plugin.analytics.cta}_${cta}` } });
      }
    }
  }

  async function installCallToAction(
    plugin: CtaPlugin | (SnapLocationData & { cta_unique_class: string; vin?: string; stock_number?: string }),
    selector: string,
    useDdcApi: boolean,
    installCtaFn: (plugin: CtaPlugin | SnapLocationData, node: HTMLElement) => void,
    retries = 0
  ) {
    if (useDdcApi) {
      return await installDDCCallToAction(plugin);
    } else {
      retries = 0;
      let containerNodes: HTMLElement[];
      try {
        containerNodes = domUtils.jqSelect<HTMLElement>(selector);
      } catch (err) {
        console.error("TradePending: Invalid selector for CTA container node", `"${selector}"`);
        return;
      }
      while (!(containerNodes?.length > 0) && retries < 4) {
        await sleep(retries++ * 500);
        containerNodes = domUtils.jqSelect<HTMLElement>(selector);
      }
      if (containerNodes?.length === 0) {
        console.error("TradePending: Could not find container node to insert call to action", selector);
      }
      if (!plugin.use_ddc_api && (plugin.use_cta || containerNodes)) {
        const use_stock_numbers = "use_stock_numbers" in plugin ? plugin.use_stock_numbers : undefined;
        const use_custom_vin_pattern = "use_custom_vin_pattern" in plugin ? plugin.use_custom_vin_pattern : undefined;
        const use_condition_check = "use_condition_check" in plugin ? plugin.use_condition_check : undefined;

        if (use_condition_check || use_stock_numbers || use_custom_vin_pattern) {
          const vehicles_identification_list = [];
          const cta_selector = use_stock_numbers ? plugin.cta_stock_number_selector : plugin.cta_vin_selector;
          containerNodes.forEach((containerNode, index) => {
            if (cta_selector) {
              const vehicle_identification = getDataFromElement(containerNode, cta_selector, use_stock_numbers ? ExtractedItem.STOCK_NUMBER : ExtractedItem.VIN);
              if (vehicle_identification) {
                vehicles_identification_list.push(vehicle_identification);
              } else {
                console.error(
                  `TradePending: Error finding ${use_stock_numbers ? "Stock Number" : "VIN"} from ${cta_selector} on containerNode[${index}], vehicle(${index + 1}), check ${
                    use_stock_numbers ? "Stock Number" : "VIN"
                  } selector under node selector. Node is :`,
                  containerNode
                );
              }
            }
          });
          if (vehicles_identification_list.length > 0) {
            let url = `//${PLUGIN_SERVER}/vin/`;
            if (use_stock_numbers) {
              url += "vinsfromstocknumbers";
              await vinsFromStockNumbersApiCache.initCacheForService(vehicles_identification_list, url);
            } else if (use_custom_vin_pattern) {
              url += "vinsfromstocknumbers?use_vins=true";
              await autoAprVinConditionApiCache.initCacheForService(vehicles_identification_list, url);
            } else {
              url += "vehiclescheck";
              await vehiclesConditionApiCache.initCacheForService(vehicles_identification_list, url);
            }
          }
        }

        containerNodes.forEach((containerNode) => {
          const node_plugin = { ...plugin, vehicle_condition: undefined, price: undefined };
          if (use_stock_numbers && node_plugin.cta_stock_number_selector) {
            const stock_number = getDataFromElement(containerNode, node_plugin.cta_stock_number_selector, ExtractedItem.STOCK_NUMBER);
            const vinFromCache = vinsFromStockNumbersApiCache.cache[stock_number]?.result;
            node_plugin.vin = vinFromCache?.vin;
            node_plugin.stock_number = vinFromCache?.stockNumber;
            if (use_condition_check) {
              node_plugin.vehicle_condition = vinFromCache;
            }
          } else if (node_plugin.cta_vin_selector) {
            node_plugin.vin = getDataFromElement(containerNode, node_plugin.cta_vin_selector, ExtractedItem.VIN);
          }
          const conditionFromCache = vehiclesConditionApiCache.cache[node_plugin.vin]?.result || autoAprVinConditionApiCache.cache[node_plugin.vin]?.result;
          const vehicleCondition = conditionFromCache?.condition || node_plugin.vehicle_condition?.condition;
          const is_new_price_selector_enabled = "price_selector_disabled_new" in node_plugin && (!vehicleCondition || vehicleCondition === VehicleCondition.NEW) && !node_plugin.price_selector_disabled_new;
          const is_used_price_selector_enabled = "price_selector_disabled_used" in node_plugin && vehicleCondition !== VehicleCondition.NEW && !node_plugin.price_selector_disabled_used;
          if ("cta_price_selector" in node_plugin && node_plugin.cta_price_selector && (is_new_price_selector_enabled || is_used_price_selector_enabled)) {
            node_plugin.price = getDataFromElement(containerNode, node_plugin.cta_price_selector, ExtractedItem.PRICE);
          }
          if (!use_condition_check || (use_condition_check(vehicleCondition) && node_plugin.vin)) {
            if (!use_stock_numbers && use_condition_check) {
              node_plugin.vehicle_condition = conditionFromCache;
            }
            installCtaFn(node_plugin, containerNode);
          }
        });
      }
    }
  }

  function installCallToActionDefault(plugin: (SnapLocationData & { cta_unique_class: string; button_selector?: string }) | CtaPlugin, containerNode: HTMLElement, retries = 0) {
    const select1 = "cta_insert_selector" in plugin ? plugin.cta_insert_selector : undefined;
    const select2 = "cta_css_selector" in plugin ? plugin.cta_css_selector : undefined;
    const cta_insert_selector = select1 || select2;
    const cta_insert_method = plugin.cta_insert_method;
    const cta_html_code = plugin.cta_html_code;
    if (!cta_insert_selector) {
      console.log("CTA: no valid css selector provided");
      return;
    }
    let cta_html: string;
    if (cta_html_code) {
      cta_html = cta_html_code;
    } else if (containerNode) {
      cta_html = getSnapCTATemplate(plugin, containerNode);
    }

    const elements = domUtils.jqSelect<HTMLElement>(cta_insert_selector, containerNode);
    let cta_elements: HTMLElement[] = elements.length ? [elements[0]] : undefined;
    if (!cta_elements) {
      cta_elements = domUtils.jqSelect(cta_insert_selector);
    }
    if (DEBUG_ENABLED) debugMessage(`Using plugin: ${getPluginLocation()} and insert method: ${cta_insert_method} on element: ${cta_elements}`);

    if (!domUtils.toElement(cta_html).querySelector(`.${plugin.cta_unique_class}`)) {
      cta_html = build_wrapper(plugin.cta_unique_class, cta_html).outerHTML;
    }
    if (cta_elements.length) {
      if (plugin.cta_unique_class) {
        const selector = `.${plugin.cta_unique_class}`;
        // Find the parent of cta_element because we sometimes insert cta_html as a sibling.
        cta_elements.forEach((node) => {
          const parentElement = node.parentElement;
          domUtils.remove(selector, parentElement);
        });

        plugin.button_selector = selector;
      }
      apply_insert_method(cta_elements, cta_insert_method, cta_html);
      if (retries >= 0) {
        installDesktopButtonListeners(plugin);
        installMobileButtonListeners(plugin);
      }
    } else {
      if (retries < 4) {
        if (DEBUG_ENABLED) debugMessage("Retrying CTA install, retry", retries + 1);
        setTimeout(() => {
          installCallToActionDefault(plugin, containerNode, retries + 1);
        }, retries * 500);
      } else {
        console.error("TradePending: TradePending CTA selector not found: " + cta_insert_selector);
      }
    }
  }

  function getSnapCTATemplate(plugin: (SnapLocationData & { cta_unique_class: string }) | CtaPlugin, containerNode: HTMLElement): string {
    const clone = containerNode.cloneNode(true) as HTMLElement;
    const button = clone.querySelector<HTMLButtonElement>(".tradepending-cta button");
    const snapCtaButton = clone.querySelector<HTMLElement>(".tradepending-cta button.tp-btn");
    if (!button) {
      if (DEBUG_ENABLED) debugMessage("TradePending: Trying to install CTA but .tradepending-cta button is missing. Is this an incorrect config or are we missing cta_html_code?");
    }
    if (plugin.cta_unique_class === "snap-cta" && snapCtaButton) {
      snapCtaButton.classList.add("snap-cta");
      addMobileOrDesktopClasses(snapCtaButton);
    }
    if (plugin.cta_button_class) {
      snapCtaButton.classList.add(plugin.cta_button_class);
    }
    button.dataset.initiator = "cta_button";
    return clone.outerHTML;
  }

  const installAutoAprFloatingButton = (location_config: AutoAprLocationData) => {
    const template_keys = Object.keys(AA_PRODUCT_MAP).filter((key) => key.match(/floating_button/));
    const elementId = "aa-floating-button";
    const id = `#${elementId}`;
    for (const template_key of template_keys) {
      const button_template = location_config.templates[template_key];
      if (document.getElementById(elementId)) {
        domUtils.remove(id);
      }
      if (!button_template || !shouldInstallWidgetOnDevice(button_template.disable_mobile, button_template.disable_desktop)) {
        return;
      }
      addEventContext("aa_cta_click", template_key, true);
      const el = domUtils.toElement(button_template.widget_html);
      el.id = elementId;
      el.addEventListener("click", (evt) => {
        const config = {
          is_autoapr: true,
          ga_category_name: AA_PRODUCT_MAP[template_key].product_name,
          url_constructor: () => `${AA_PRODUCT_MAP[template_key].url}${AUTOAPR_CONFIG.dealer_id}/`,
          analytics: { cta: template_key },
        };
        fire_autoapr_start_events(config, evt);
        launchOverlay(config, launchColorboxFullProcessPopup);
      });
      document.body.append(el);
      domUtils.addCssToHead(template_key, button_template.widget_css);
      postAnalytics("aa_exp_view_float");
    }
  };

  function installFloatingButton() {
    const plugin_location = getPluginLocation();
    const snapPlugin = PLUGIN_DATA[plugin_location];
    if (!shouldInstallWidgetOnDevice(snapPlugin?.floating_button_disable_mobile, snapPlugin?.floating_button_disable_desktop)) {
      return;
    }

    const float = document.getElementById("tradepending-floating");
    if (float) {
      const button = float.querySelector<HTMLButtonElement>("button");
      if (button.dataset.avoidTransforms === "true") {
        // detaches and adds to body
        document.body.appendChild(float);
      }
      button.dataset.initiator = "floating_button";
      addMobileOrDesktopClasses(button);
      if (button.dataset.pinTo && document.querySelector(button.dataset.pinTo)) {
        const positionButton = () => {
          const element = document.querySelector(button.dataset.pinTo);
          const right = element.getBoundingClientRect().right;
          let offset = button.dataset.pinOffset;
          if (is_mobile && button.dataset.pinOffsetMobile) {
            offset = button.dataset.pinOffsetMobile;
          }
          if (!offset) {
            offset = "0";
          }
          const viewportWidth = domUtils.windowWidth();
          // offset contains 'px', and parseInt strips it
          float.style.right = `${viewportWidth - right + parseInt(offset, 10)}px`;
        };
        positionButton();
        window.addEventListener("resize", positionButton);

        const container = document.querySelector<HTMLElement>("#tradepending-container");
        if (button.dataset.scrollHide === "true" && container) {
          const setButtonVisibility = (fadeDuration: number) => {
            const rect = container.getBoundingClientRect();
            const top = rect.top + window.scrollY;
            const bottom = top + container.offsetHeight;
            const windowTop = window.scrollY;
            const windowBottom = windowTop + window.innerHeight;
            if (bottom > windowTop && top < windowBottom) {
              if (!float.classList.contains("tp-fade-out")) {
                float.classList.remove("tp-fade-in");
                float.classList.add("tp-fade-out");
              }
            } else if (!float.classList.contains("tp-fade-in")) {
              domUtils.show(float);
              float.classList.remove("tp-fade-out");
              float.classList.add("tp-fade-in");
            }
          };
          let fade = 300;
          if (button.dataset.scrollHideFade) {
            try {
              fade = parseInt(button.dataset.scrollHideFade);
            } catch (ex) {
              console.error("TradePending: Could not parse scroll hide fade duration: " + button.dataset.scrollHideFade);
            }
          }
          setButtonVisibility(0);

          const handler = () => {
            setButtonVisibility(fade);
          };
          window.addEventListener("resize", handler, { passive: true });
          window.addEventListener("scroll", handler, { passive: true });
        } else {
          domUtils.show(float);
        }
      } else {
        console.error("TradePending: Could not find element used to set floating button position: ");
      }
    } else {
      if (DEBUG_ENABLED) debugMessage("No #tradepending-floating found. Nothing to do.");
    }
  }

  function installButtonListeners(buttons: HTMLElement | HTMLElement[], fn: (evt: MouseEvent) => void) {
    const elements = Array.isArray(buttons) ? buttons : [buttons];
    elements.forEach((btn) => {
      if (!btn.dataset.tpListenerInstalled) {
        btn.dataset.tpListenerInstalled = "true";
        btn.addEventListener("click", fn);
      }
    });
  }

  const fireCtaEvent = (event: Event | null, plugin: SnapLocationData | CtaPlugin, action = "cta_button") => {
    let initiator = "button";
    if (event?.target && (event.target as HTMLElement).dataset.initiator) {
      initiator = (event.target as HTMLElement).dataset.initiator;
      postAnalytics(initiator);
    } else {
      postAnalytics(action);
    }
    if ("is_autoapr" in plugin && plugin.is_autoapr) {
      fire_autoapr_start_events(plugin, event);
    } else {
      if (initiator === "button") {
        let ctaText = plugin.cta_button_text;
        if (!ctaText) {
          const target = event?.target as HTMLElement;
          ctaText = target?.innerText?.split("\n")[0]; // If it contains a title and subtitle, this will give us just the title
        }
        fire_asc_cta_event({ product: "Trade", actionResult: "open", elementText: ctaText, vehicle: "vehicle_condition" in plugin ? plugin.vehicle_condition : undefined });
      }
      fire_tradein_start_events(initiator);
    }
  };

  const mobileButtonListenerFunc = (plugin: SnapLocationData | CtaPlugin | ValueTrackerLocationData, options?: { is_value_tracker: boolean }) => (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    fireCtaEvent(event, plugin, "widget_button");
    const button = event.currentTarget as HTMLElement;
    let popup_initiator = "mobile_widget";

    if (button.dataset.initiator) {
      popup_initiator = button.dataset.initiator;
    } else if (button.classList.contains("snap-cta")) {
      popup_initiator = "cta";
    }
    const iframe = buildVehicleSelectFrame({
      is_mobile: true,
      is_mobile_overlay: true,
      show_close_button: true,
      plugin,
      classes: "overlay-iframe",
      popup_initiator,
      is_value_tracker: options?.is_value_tracker,
    });
    launchMobileOverlay(iframe);
  };

  function installMobileButtonListeners(plugin: SnapLocationData | CtaPlugin) {
    // entry point for mobile
    const button_selector = ("button_selector" in plugin ? plugin.button_selector : undefined) || ".tp-btn-vehicle-select";
    installButtonListeners(Array.from(document.querySelectorAll(button_selector)), mobileButtonListenerFunc(plugin));
  }

  const installMutationObserver = (plugin_config: PluginConfig) => {
    if (plugin_config.reinstall_location_change) {
      const debouncedInstall = debounce(500, install);
      window.addEventListener("tradepending-route-change", () => {
        if (DEBUG_ENABLED) debugMessage("tradepending-route-change fired, re-installing");
        debouncedInstall();
      });

      window.addEventListener("popstate", (event) => {
        if (DEBUG_ENABLED) debugMessage("back button / popstate, re-installing");
        debouncedInstall();
      });
      let oldHref = document.location.href;
      let bodyDOM = document.querySelector("body");
      const config = {
        childList: true,
        subtree: true,
      };
      const observer = new MutationObserver(function (mutations) {
        if (oldHref != document.location.href) {
          observer.disconnect();
          oldHref = document.location.href;
          domUtils.remove("#tradepending-container");

          window.requestAnimationFrame(function () {
            debouncedInstall();
            const tmp = document.querySelector("body");

            if (tmp != bodyDOM) {
              bodyDOM = tmp;
              observer.observe(bodyDOM, config);
            }
          });
        }
      });
      observer.observe(bodyDOM, config);
    }
  };

  const desktopButtonListenerFunc = (plugin: SnapLocationData | CtaPlugin) => (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    fireCtaEvent(event, plugin);
    const popup_initiator = (event.target as HTMLElement)?.dataset.initiator || "cta";
    launchOverlay(plugin, launchColorboxFullProcessPopup, popup_initiator);
  };

  function installDesktopButtonListeners(plugin: SnapLocationData | CtaPlugin) {
    // entry point for showing the full vehicle selection process, contact form, additional attributes, report
    const button_selector = ("button_selector" in plugin && plugin.button_selector !== ".snap-cta" && plugin.button_selector) || ".tp-btn-full-frame";
    installButtonListeners(Array.from(document.querySelectorAll(button_selector)), desktopButtonListenerFunc(plugin));
  }

  function addMobileOrDesktopClasses(button: HTMLElement) {
    if (isMobile()) {
      button.classList.remove("tp-btn-full-frame");
      button.classList.add("tp-btn-vehicle-select");
    } else {
      button.classList.remove("tp-btn-vehicle-select");
      button.classList.add("tp-btn-full-frame");
    }
  }

  function launchOverlay(plugin: SnapLocationData | { url_constructor?: (vin?: string) => string; additional_params?: { internal_usage: boolean } }, desktopLaunchMethod, popup_initiator = "cta") {
    if (is_mobile) {
      const iframe = buildVehicleSelectFrame({
        popup_initiator: popup_initiator,
        is_mobile: true,
        is_mobile_overlay: true,
        show_close_button: true,
        plugin,
        classes: "overlay-iframe",
      });
      launchMobileOverlay(iframe);
    } else {
      desktopLaunchMethod(plugin, popup_initiator);
    }
  }

  function installSuperlatives() {
    if (SUPERLATIVE_CONFIG && typeof SUPERLATIVE_CONFIG === "object" && shouldShowSuperlatives(getPluginLocation())) {
      if (DEBUG_ENABLED) debugMessage("superlatives enabled with data:", SUPERLATIVE_CONFIG);
      const superlative_location_config = setup_superlatives_location_config(SUPERLATIVE_CONFIG);
      if (DDC_API && superlative_location_config.use_ddc_api) {
        setup_superlatives_ddc();
      } else {
        setup_superlatives();
      }
    }
  }

  function installSnapcellScript() {
    if (SNAPCELL_CHAT_ENABLED) {
      if (!hasLegacyChat()) {
        if (DEBUG_ENABLED) debugMessage("Installing snapcell chat");
        installSnapcellChat();
      } else {
        if (DEBUG_ENABLED) debugMessage("Skipping snapcell chat install--using legacy install");
      }
    }

    if (SNAPCELL_VIDEO_ENABLED || SNAPCELL_SPIN360_ENABLED) {
      if (DDC_API) {
        DDC_API.subscribe("page-load-v1", (ev) => {
          if (SNAPCELL_CONFIG.enable_video_script) {
            if (ev.payload.detailPage) {
              installVideoWithDDC(SNAPCELL_ID, DDC_API);
            } else if (ev.payload.searchPage) {
              installSrpCtasWithDDC(SNAPCELL_ID, DDC_API);
            }
          }
          if (SNAPCELL_CONFIG.enable_spin360_script && ev.payload.detailPage) {
            installSpinWithDDC(DDC_API);
          }
        });
      } else if (getPluginLocation() === "vdp") {
        let nodeSelector = SNAPCELL_CONFIG.node_selector;
        let vinSelector = SNAPCELL_CONFIG.vin_selector;
        if (SNAPCELL_CONFIG.use_superlatives_config_for_vin) {
          const selectors = getSelectorsForSuperlatives();
          if (selectors) {
            nodeSelector = selectors.node_selector;
            vinSelector = selectors.vin_selector;
          }
        }
        const vinList = [];
        if (nodeSelector && vinSelector) {
          const containerNodes = document.querySelectorAll(nodeSelector);
          for (const element of Array.from(containerNodes)) {
            const result = getDataFromElement(element, vinSelector, ExtractedItem.VIN);
            if (result) {
              vinList.push(result);
            }
          }
        }
        if (vinList.length > 1) {
          if (DEBUG_ENABLED) debugMessage("Too many vins found for snapcell video script, skipping install");
        } else if (vinList.length === 1) {
          if (SNAPCELL_CONFIG.enable_video_script) {
            installVideo(SNAPCELL_ID, vinList[0], SNAPCELL_CONFIG.video_node_selector, SNAPCELL_CONFIG.preload_disabled);
          }

          if (SNAPCELL_CONFIG.enable_spin360_script) {
            installSpin(vinList[0], SNAPCELL_CONFIG.spin_node_selector);
          }
        }
      }
    }
  }

  function getSelectorsForSuperlatives() {
    const pluginLocation = getPluginLocation();
    if (SUPERLATIVE_CONFIG && typeof SUPERLATIVE_CONFIG === "object" && SUPERLATIVE_CONFIG?.locations?.[pluginLocation]) {
      const desktop_config = SUPERLATIVE_CONFIG.locations[pluginLocation];
      const mobile_config = SUPERLATIVE_CONFIG.locations[pluginLocation + "_mobile"];
      let vin_selector = desktop_config.vin_selector;
      let node_selector = desktop_config.node_selector;
      if (isMobile() && mobile_config) {
        if (mobile_config.vin_selector) {
          vin_selector = mobile_config.vin_selector;
        }
        if (mobile_config.node_selector) {
          node_selector = mobile_config.node_selector;
        }
      }
      return {
        vin_selector,
        node_selector,
      };
    }
    if (DEBUG_ENABLED) debugMessage("Getting selectors for superlatives but SUPERLATIVE_CONFIG or plugin location is not defined");
  }

  function generateId(size: number) {
    let id = "";
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < size; i++) {
      id += chars[Math.floor(Math.random() * chars.length)];
    }
    return id;
  }

  function maybeLaunchApprove() {
    const queryParams = parse_query_params();
    if (queryParams?.["tp_launch_approve"]) {
      if (DEBUG_ENABLED) debugMessage("SNAP.maybeLaunchApprove() launching approve");
      const url = `${AA_PRODUCT_MAP["approve_cta"].url}${AUTOAPR_CONFIG.dealer_id}/`;
      const internal_usage = !!queryParams["tp_internal_usage"];
      const config = {
        url_constructor: () => url,
        additional_params: { internal_usage },
      };
      launchOverlay(config, launchColorboxFullProcessPopup);
    }
  }

  const maybeLaunchAutobio = () => {
    const queryParams = parse_query_params();
    if (queryParams) {
      if (DEBUG_ENABLED) debugMessage("SNAP.maybeLaunchAutobio() maybe launching autobio or package item");
      const vin = queryParams["tp_autobio_vin_code"] || queryParams["tp_autobio_vin"];
      const shortId = queryParams["tp_autobio_shortid"];

      const packageId = queryParams["tp_package"];
      const leadId = queryParams["tp_lead"];
      let url = "";
      const showPackageItem = packageId && shortId && leadId;
      const showAutobioReport = vin && shortId;
      if (showPackageItem) {
        url = `//${PLUGIN_SERVER}/apps/autobio/api/package/item-details/${shortId}/${packageId}/${leadId}`;
        postAnalytics("autobio_package_auto_launch", { vin, packageId, leadId });
      } else if (showAutobioReport) {
        const dealerUserId = queryParams["tp_dealer_user_id"];
        const shareType = queryParams["st"];
        const userQueryString = dealerUserId ? `&userId=${dealerUserId}` : ``;
        url = `//${PLUGIN_SERVER}/apps/autobio/bio/${shortId}/${vin}?st=${shareType}${userQueryString}`;
        postAnalytics("autobio_report_auto_launch", { vin, shortId });
      }

      const config = {
        url_constructor: () => url,
      };
      if (url) {
        launchOverlay(config, launchColorboxFullProcessPopup);
      }
    }
  };

  const maybeLaunchVideoIframe = async () => {
    const queryParams = parse_query_params();
    if (queryParams) {
      if (DEBUG_ENABLED) debugMessage("SNAP.maybeLaunchVideoIframe maybe launching videos");
      let videoUrl = queryParams["tp_video_url"];

      const config = {
        url_constructor: () => videoUrl,
      };
      if (videoUrl) {
        videoUrl = decodeURIComponent(videoUrl);
        launchOverlay(config, launchColorboxFullProcessPopup);
        const customerHash = queryParams["tp_cust_hash"];
        linkCustomer(customerHash);
      }
    }
  };

  const linkCustomer = async (customerHash: string) => {
    if (tp_linking_id && customerHash) {
      const recordLink = `//${PLUGIN_SERVER}/snapcell/link/${customerHash}?linking_id=${tp_linking_id}&dealer_id=${DEALER_ID}`;
      try {
        await fetch(recordLink, { method: "POST" });
      } catch (err) {
        // no op
      }
    }
  };

  async function install() {
    if (DEBUG_ENABLED) debugMessage("SNAP.install()");
    linkPromise = setupLink();
    const r = document.cookie.match(/tpa_session=(.*?)(?:;|$)/);
    if (r) {
      tp_analytics_session_id = r[1];
      if (DEBUG_ENABLED) debugMessage("Using existing tp_analytics_session_id: " + tp_analytics_session_id);
    } else {
      tp_analytics_session_id_is_new = true;
      tp_analytics_session_id = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
      if (DEBUG_ENABLED) debugMessage("Created new tp_analytics_session_id: " + tp_analytics_session_id);
    }
    if (DEBUG_ENABLED) debugMessage("SNAP debugging enabled");
    if (DEBUG_ENABLED) debugMessage("plugin_config: ", PLUGIN_CONFIG);
    if (DEBUG_ENABLED) debugMessage("plugin_data: ", PLUGIN_DATA);

    const init_cookie_match = document.cookie.match(/tp_initial_url=(.*?)(?:;|$)/);
    if (!init_cookie_match) {
      const maxage = 3 * 24 * 60 * 60;
      document.cookie = "tp_initial_url=" + window.location.href + "; max-age=" + maxage + ";";
      if (typeof document.referrer !== "undefined") {
        document.cookie = "tp_referrer_url=" + document.referrer + "; max-age=" + maxage + ";";
      }
    }

    const tpa_vdp_match = document.cookie.match(/tpa_vdp=(.*?)(?:;|$)/);
    if (!tpa_vdp_match || !tpa_vdp_match[1]) {
      document.cookie = "tpa_vdp=" + generateId(17) + "; max-age=" + 90 * 24 * 60 * 60 + ";";
    }

    const path = getPath();
    if (DEBUG_ENABLED) debugMessage("Using current path of: " + path);
    const plugin_location = getPluginLocation();

    initAnalytics(plugin_location);

    if (HAS_DDC) {
      DDC_API = await ddcUtils.waitForDDCAPI();
    }

    if (typeof window.sd === "function" && SHIFT_OEMS.includes(PLUGIN_CONFIG.program_oem)) {
      if (DEBUG_ENABLED) debugMessage("Asking for shift sessionId");
      window.sd("getSessionId", (sessionId) => {
        if (DEBUG_ENABLED) debugMessage("Setting shift session id in cookie: ", sessionId);
        document.cookie = "tp_shift_session_id=" + sessionId;
      });
    }
    if (DEBUG_ENABLED) debugMessage("Using plugin location: " + plugin_location);
    domUtils.insertStyles(GLOBAL_STYLES);

    // Call 'off' first in case install() has been called before
    window.removeEventListener("message", receiveMessage);
    window.addEventListener("message", receiveMessage);

    domUtils.addCssLinkToHead("tradepending-fa", "https://cdn.tradepending.com/fontawesome_kits/kit-477fb8cd51-web/css/all.min.css");
    if (SUPERLATIVES_ENABLED) {
      installSuperlatives();
    }
    installSnapcellScript();
    if (PLUGIN_CONFIG.snap_pending) {
      postAnalytics("notify");
    }

    const plugin = PLUGIN_DATA[plugin_location];
    if (plugin_location == null || typeof plugin === "undefined") {
      if (DEBUG_ENABLED) debugMessage("SNAP Plugin not configured for path: " + path);
      snap_widget_installed = true;
    } else if (isBlockedStagingSite(PLUGIN_CONFIG.snap_status)) {
      if (DEBUG_ENABLED) debugMessage("SNAP not displaying because it's a staging site that doesn't match the configured staging url: " + PLUGIN_CONFIG.staging_url);
      snap_widget_installed = true;
    } else if (PLUGIN_DATA[plugin_location].status === "disabled") {
      if (DEBUG_ENABLED) debugMessage("SNAP not displaying because disable flag is set for location: " + plugin_location);
      snap_widget_installed = true;
    } else if (!shouldInstallBannerOrCta(plugin)) {
      if (DEBUG_ENABLED) debugMessage("SNAP not displaying because mobile/desktop or new/used disable flag is set for location: " + plugin_location);
      snap_widget_installed = true;
    } else {
      if (typeof plugin.locale !== "undefined") {
        plugin_page_locale = plugin.locale;
      }

      if (typeof plugin?.css !== "undefined") {
        domUtils.addCssToHead(`tp_snap_custom_css`, plugin.css);
      }
      if (typeof plugin?.css_compiled !== "undefined") {
        domUtils.addCssToHead(`tp_snap_css_template`, plugin.css_compiled);
      }
      let widget_html = "";
      if (typeof plugin?.html_template !== "undefined") {
        widget_html += plugin.html_template;
      } else if (typeof plugin?.html_compiled !== "undefined") {
        widget_html += plugin.html_compiled;
      } else if (typeof plugin?.html_code !== "undefined") {
        widget_html = plugin.html_code;
      }
      widget_html = "<div style='clear: both;'></div>" + widget_html + "<div style='clear: both;'></div>";

      if (tpUtil.isIE() && !is_mobile) widget_html = widget_html.replace(new RegExp(/(@media\s*\(max-width:\s*767px\)\s*\{[\s\S]*?}\s*})/gim), "");

      if (!SNAP_ENABLED) {
        if (DEBUG_ENABLED) debugMessage("SNAP not displaying because disable flag is set");
        snap_widget_installed = true;
      } else {
        await insert_widget(plugin, widget_html);
      }
      if (snap_widget_installed && SNAP_ENABLED) {
        enableDisplay();
        if (!vrp_ready_listener_installed && plugin_location === "srp" && plugin?.allow_multiple) {
          installReloadListeners(PLUGIN_CONFIG);
        }

        const typeahead = installTypeahead();

        typeahead.once("query").then(() => {
          postAnalytics("typeahead");
        });

        typeahead.on("select", (vehicle) => {
          if (DEBUG_ENABLED) debugMessage("TradePending: Fire typeahead");
          let initiator = "button";
          fire_tradein_start_events(initiator);
          fire_vehicle_selected_events(vehicle);
          launchContactForm(vehicle);
        });

        installFloatingButton();

        installMobileButtonListeners(plugin);
        installMutationObserver(PLUGIN_CONFIG);
        setupAccessibility();
        preventInputHighjacks();
      }
      installDesktopButtonListeners(plugin);
    }
    if (AUTOAPR_ENABLED) {
      debouncedInstallAutoApr = debounce(500, installAutoApr);
      await installAutoApr();
    }
    if (AUTOAPR_ENABLED || CTAS_ENABLED || SNAP_ENABLED) {
      debouncedInstallCtas = debounce(500, installCtas);
      await installCtas();
    }
    if (AUTOAPR_ENABLED) {
      maybeLaunchApprove();
    }

    if (VALUE_TRACKER_ENABLED) {
      await installValueTracker();
    }

    maybeLaunchAutobio();

    maybeLaunchVideoIframe();

    if (typeof handleSnapAfterAnalytics === "function") {
      handleSnapAfterAnalytics({ type: "vdp" });
    }

    tpUtil.setup_refresh_events(PLUGIN_LOCATION_CONFIGS, () => {
      const product_plugin_location_config = PLUGIN_LOCATION_CONFIGS[getPluginLocation()] || {};
      setTimeout(() => {
        if (debouncedInstallCtas) {
          debouncedInstallCtas();
        }
        if (debouncedInstallAutoApr) {
          debouncedInstallAutoApr();
        }
        if (debouncedInstallCtas || debouncedInstallAutoApr) {
          setTimeout(() => {
            product_plugin_location_config.has_been_setup = false;
          }, 500);
        }
      }, 50);
    });
    if (plugin_location && SELLNOW_CONFIG?.instant_locations?.find((loc) => loc === plugin_location)) {
      fire_snapoffer_autostart_event();
    }
  }

  async function setupLink() {
    const tplink = getCookie(`tp_link`);
    if (tplink) {
      tp_linking_id = tplink;
    }

    const script = document.createElement("script");
    let url = `${PLUGIN_AJAX_URL}/link/${encodeURIComponent(DEALER_ID)}/dealer.js`;
    if (tplink) {
      url += `?lid=${tplink}`;
    }
    script.setAttribute("src", url);
    script.setAttribute("async", "true");
    return new Promise<void>((resolve, reject) => {
      script.onload = () => {
        if (!tp_linking_id) {
          tp_linking_id = getCookie("tp_link");
        }
        resolve();
      };

      script.onerror = () => {
        resolve();
      };
      document.body.appendChild(script);
    });
  }

  function apply_insert_method(el: HTMLElement | HTMLElement[], method: InsertMethod, html: string | HTMLElement) {
    const elements = Array.isArray(el) ? el : [el];
    elements.forEach((element) => {
      if (typeof html === "string") {
        domUtils.insertHtml(element, method, html);
      } else if (html instanceof HTMLElement) {
        domUtils.insertDOMElement(element, method, html);
      }
    });
  }

  async function insert_widget(plugin: SnapLocationData, widget_html: string, using_default_plugin_location?: boolean) {
    if (DEBUG_ENABLED) debugMessage("TradePending: insert_widget");
    if (DDC_API && ddcUtils.isDdcLandingPage() && !ddcUtils.isOldDdcLandingPage()) {
      if (DEBUG_ENABLED) debugMessage("TradePending: Using DDC API for SNAP Landing Page insert");
      await installSnapDdcLandingPage(plugin, widget_html);
    } else if (DDC_API && plugin.use_ddc_api) {
      if (DEBUG_ENABLED) debugMessage("TradePending: Using DDC API for SNAP insert");
      await installSnapDdcWidget(plugin, widget_html);
    } else {
      insert_default(plugin, widget_html, using_default_plugin_location, 0);
    }
  }

  function maybeInstall() {
    if (!snap_widget_installed) {
      install();
    }
  }

  if (document.readyState === "complete" || document.readyState === "interactive") {
    // If the document is already fully loaded
    maybeInstall();
  } else {
    // If the document is not yet fully loaded, listen for the load event
    window.addEventListener("load", maybeInstall);
  }

  window.tradependingSetup = () => {
    if (DEBUG_ENABLED) debugMessage("tradependingSetup calling install");
    snap_widget_installed = false;
    install();
  };
  window.tradendingLaunchSnapOverlay = (vehicle) => {
    launchContactForm(vehicle);
  };
  window.tradependingLaunchSnapOverlay = (vehicle) => {
    launchContactForm(vehicle);
  };
  return true;
}

bootstrap();
